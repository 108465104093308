import React from 'react';
import {useDispatch} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {Container, Typography} from '@material-ui/core';

import Form from 'components/CommissionRule/Form/Form';
import {FormWrapper} from 'components/Common/Styled/FormWrapper';
import {CommissionRule} from "types/CommissionRule/CommissionRule";
import {saveCommissionRule} from "actions/CommissionRule";
import {useShallowEqualSelector} from "modules/hooks";
import {Dictionaries} from "types/Dictionaries/Dictionaries";

function Update() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<CommissionRule>();

  const dictionaries = useShallowEqualSelector<Dictionaries>(({ dictionaries }) => dictionaries);

  const handleSubmit = (commissionRule: CommissionRule) => {
    dispatch(saveCommissionRule(commissionRule));
    history.goBack();
  };

  const getTitle = () => {
    return location?.state
      ? `Изменение Commission Rules #${location?.state?.id}`
      : 'Создание Commission Rules';
  };

  return (
    <div>
      <Container maxWidth="sm">
        <FormWrapper>
          <Typography variant="h6">{getTitle()}</Typography>
          <Form handleSubmit={handleSubmit} commissionRule={location.state} dictionaries={dictionaries}/>
        </FormWrapper>
      </Container>
    </div>
  );
}

export default Update;
