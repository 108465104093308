import {FormControl, TextField} from '@material-ui/core';
import styled from 'styled-components';

export const FilteredTableControlsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 0 0;
`;

export const FilteredTableControl = styled(FormControl)`
  width: 100%;
  min-width: 200px;
`;

export const FilteredTableInputControl = styled(TextField)`
  width: 100%;
  min-width: 200px;
`;

export const FilteredTableDatePickerControl = styled.div`
  width: 100%;
  min-width: 200px;
`;
