import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Route, Router, Switch} from 'react-router-dom';
import {createStyles, CssBaseline, makeStyles, Theme} from '@material-ui/core';

import history from 'modules/history';
import {useShallowEqualSelector} from 'modules/hooks';

import config from 'config';

import {MainDrawer} from 'components/Layout/MainDrawer';
import RoutePrivate from 'containers/RoutePrivate';
import RoutePublic from 'containers/RoutePublic';
import LeadGeneratorTemplate from 'routes/LeadGeneratorTemplate';
import Login from 'routes/Login';
import NotFound from 'routes/NotFound';
import Postback from 'routes/Postback';
import Private from 'routes/Private';

import {StoreState} from 'types';
import CommissionRule from "routes/CommissionRule";
import LeadGenerator from "routes/LeadGenerator";

const drawerWidth = 300;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
    },
  }),
);

function Root() {
  const {isAuthenticated} = useShallowEqualSelector((s: StoreState) => s.user);

  const classes = useStyles();

  return (
    <Router history={history}>
      <Helmet
        defaultTitle={config.name}
        defer={false}
        encodeSpecialCharacters
        htmlAttributes={{lang: 'pt-br'}}
        titleAttributes={{itemprop: 'name', lang: 'pt-br'}}
        titleTemplate={`%s | ${config.name}`}
      >
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <div className={classes.root}>
        <CssBaseline/>

        {isAuthenticated && <MainDrawer classes={classes}/>}

        <main className={classes.content}>
          <Switch>
            <RoutePublic
              component={Login}
              exact
              isAuthenticated={isAuthenticated}
              path="/"
              to="/postback_event_message"
            />
            <RoutePrivate component={Private} isAuthenticated={isAuthenticated} path="/private"/>
            <RoutePrivate component={LeadGeneratorTemplate} isAuthenticated={isAuthenticated}
                          path="/lead_generator_template"/>
            <RoutePrivate component={Postback} isAuthenticated={isAuthenticated} path="/postback_event_message"/>
            <RoutePrivate component={CommissionRule} isAuthenticated={isAuthenticated}
                          path="/postback_commission_rule"/>
            <RoutePrivate component={LeadGenerator} isAuthenticated={isAuthenticated} path="/lead_generator"/>
            <Route component={NotFound}/>
          </Switch>
        </main>
      </div>
    </Router>
  );
}

export default Root;

