import { createReducer } from 'modules/helpers';

import { ActionTypes, STATUS } from 'literals';

import { Dictionaries } from 'types/Dictionaries/Dictionaries';

export const dictionariesState: Dictionaries = {
  eventTypes: [],
  brands: [],
  enabled: [],
  status: STATUS.IDLE,
  leadGenerator: []
};

export default {
  dictionaries: createReducer<Dictionaries>(
    {
      [ActionTypes.GET_DICTIONARIES_SUCCESS]: (draft, { payload }) => {
        draft.brands = payload.brands;
        draft.eventTypes = payload.eventTypes;
        draft.leadGenerator = payload.leadGenerator;
        draft.enabled = payload.enabled;
        draft.status = payload.status;
      },
    },
    dictionariesState,
  ),
};
