import React, {useState} from 'react';
import MomentUtils from "@date-io/moment";

import moment from 'moment';
import 'moment/locale/ru'
import {DatePicker as MuiDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";

moment.locale("ru")

interface DatePickerProps {
  title: string;
  value: string | null;
  disabled?: boolean;
  size?: string

  onChange(func: any): any
}

/**
 * Кастомный компонент DateTimePicker, использует moment
 * @param title
 * @param value
 * @param onChange
 * @param disabled
 * @param size
 * @constructor
 */
function DatePicker({title, value = null, onChange, disabled = false, size = 'normal'}: DatePickerProps) {
  const [clearedDate, handleClearedDateChange] = useState(value?.length > 0 ? value : null);

  const handleDate = (event: moment) => {
    if (event) {
      handleClearedDateChange(event.set({"hour": 0, "minute": 0}).toISOString(true))
      onChange({target: {value: event.set({"hour": 0, "minute": 0}).toISOString(true)}})
    } else {
      handleClearedDateChange(null)
      onChange({target: {value: ''}})
    }
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale={"ru"}>
      <MuiDatePicker
        clearable
        size={size}
        variant="dialog"
        format="DD.MM.yyyy"
        ampm={false}
        label={title}
        disabled={disabled}
        inputVariant="outlined"
        value={clearedDate}
        onChange={handleDate}
      />
    </MuiPickersUtilsProvider>
  );
}

export {DatePicker};
