import React from 'react';
import { useLocation } from 'react-router-dom';
import { IconButton, Typography } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import styled from 'styled-components';

import { routes } from 'routes/Routes';

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

function getTitle(path: string): string {
  return routes.find(route => path.includes(route.path))?.title || '';
}

export default function Header() {
  const location = useLocation();
  const title = getTitle(location.pathname);

  return (
    <HeaderWrapper>
      <Typography noWrap variant="h6">
        {title}
      </Typography>

      <IconButton aria-label="account of current user" color="inherit" edge="end">
        <AccountCircle />
      </IconButton>
    </HeaderWrapper>
  );
}
