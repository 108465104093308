import React from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useMount} from 'react-use';
import {
  CircularProgress,
  Fab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import {useShallowEqualSelector} from 'modules/hooks';

import {STATUS} from 'literals';

import {getDictionaries, getLeadGeneratorTemplate, setFilters} from 'actions';
import {InputFilter} from 'components/Filters/Input/InputFilter';
import {DictionarySelector} from 'components/Filters/Selectors/DictionarySelector';
import {PerPageSelector} from 'components/PerPageSelector/PerPageSelector';
import {
  TableControls,
  TableLoadingWrapper,
  TableWrapper,
} from 'components/Common/Table/Styled/TableWrapper';

import {Dictionaries} from 'types/Dictionaries/Dictionaries';
import {FiltersState} from 'types/Filters/FiltersState';
import {PerPageViewer} from 'types/PerPageViewer/PerPageViewer';
import {Add, CloudDownload} from "@material-ui/icons";
import {DateTimeFilter} from "components/Filters/DateTime/DateTimeFilter";
import {FilteredTableDatePickerControl} from "components/Common/Table/Styled/FilterWrapper";
import {getFormatedDate} from "utils/helpers";
import {LeadGeneratorTemplate, LeadGeneratorTemplateState} from "types/LeadGeneratorTemplate/LeadGeneratorTemplate";

function LeadGeneratorTemplateTable() {
  const history = useHistory();

  const filters = useShallowEqualSelector<FiltersState>(({filters}) => filters);
  const perPageViewer = useShallowEqualSelector<PerPageViewer>(
    ({perPageViewer}) => perPageViewer,
  );
  const dictionaries = useShallowEqualSelector<Dictionaries>(({dictionaries}) => dictionaries);
  const leadState: LeadGeneratorTemplateState = useShallowEqualSelector<LeadGeneratorTemplateState>(({leadGeneratorTemplate}) => leadGeneratorTemplate);

  const selectLead = (leadGenerator: LeadGeneratorTemplate | null = null) => {
    history.push('/lead_generator_template/update', leadGenerator);
  };

  const dispatch = useDispatch();

  useMount(() => {
    dispatch(getDictionaries());
    dispatch(getLeadGeneratorTemplate({resetPaginator: true}));
  });

  const useFilter = (stack: string[], value: string | boolean) => {
    const save = {
      [stack[0]]: {
        [stack[1]]: value,
      },
    };

    dispatch(setFilters({...filters, ...save}));
    dispatch(getLeadGeneratorTemplate());
  };

  return (
    <div>
      <TableWrapper>
        {leadState.status != STATUS.SUCCESS || (
          <TableControls>
            <div>
              <Fab
                aria-label="add"
                color="primary"
                onClick={() => selectLead()}
                size="small"
                style={{marginRight: '10px'}}
              >
                <Add/>
              </Fab>
              <Fab aria-label="add" color="primary" size="small">
                <CloudDownload/>
              </Fab>
            </div>
            <PerPageSelector actionDispatcher={getLeadGeneratorTemplate} perPageViewer={perPageViewer}/>
          </TableControls>
        )}
        <TableContainer>
          {leadState.status === STATUS.SUCCESS ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell component="th">
                    <DictionarySelector
                      current={filters.leadGeneratorTemplate.brand}
                      dictionaries={dictionaries.brands}
                      dispatcher={useFilter}
                      stack={['leadGeneratorTemplate', 'brand']}
                      title="Бренд"
                    />
                  </TableCell>
                  <TableCell>
                    <DictionarySelector
                      current={filters.leadGeneratorTemplate.eventType}
                      dictionaries={dictionaries.eventTypes}
                      dispatcher={useFilter}
                      stack={['leadGeneratorTemplate', 'eventType']}
                      title="Тип ивента"
                    />
                  </TableCell>
                  <TableCell>
                    <DictionarySelector
                      current={filters.leadGeneratorTemplate.leadGenerator}
                      dictionaries={dictionaries.leadGenerator}
                      dispatcher={useFilter}
                      setNameFromObject={"name"}
                      stack={['leadGeneratorTemplate', 'leadGenerator']}
                      title="Lead generator"
                    />
                  </TableCell>
                  <TableCell>
                    <InputFilter
                      current={filters.leadGeneratorTemplate.tariff}
                      dispatcher={useFilter}
                      type="number"
                      stack={['leadGeneratorTemplate', 'tariff']}
                      title="Тариф"
                    />
                  </TableCell>
                  <TableCell>
                    <InputFilter
                      current={filters.leadGeneratorTemplate.template}
                      dispatcher={useFilter}
                      stack={['leadGeneratorTemplate', 'template']}
                      title="Шаблон"
                    />
                  </TableCell>
                  <TableCell>
                    <FilteredTableDatePickerControl>
                      <DateTimeFilter
                        size="medium"
                        current={filters.leadGeneratorTemplate.created}
                        dispatcher={useFilter}
                        stack={['leadGeneratorTemplate', 'created']}
                        title="Создан"
                        disabled={true}
                      />
                    </FilteredTableDatePickerControl>
                  </TableCell>
                  <TableCell>
                    <DictionarySelector
                      current={filters.leadGeneratorTemplate.enabled}
                      dictionaries={dictionaries.enabled}
                      dispatcher={useFilter}
                      stack={['leadGeneratorTemplate', 'enabled']}
                      title="Включен"
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leadState.leadGeneratorTemplate.map((lead) => {
                  return (
                    <TableRow
                      key={lead.id}
                      hover
                      onClick={() => selectLead(lead)}
                      style={{
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                      }}
                    >
                      <TableCell>
                        <div>{lead.brand}</div>
                      </TableCell>
                      <TableCell>
                        <div>{lead.eventType}</div>
                      </TableCell>
                      <TableCell>
                        <div>{lead.leadGenerator.name}</div>
                      </TableCell>
                      <TableCell>
                        <div>{lead.tariff}</div>
                      </TableCell>
                      <TableCell style={{maxWidth: 200}}>
                        <div>{lead.template}</div>
                      </TableCell>
                      <TableCell>
                        <div>{getFormatedDate(lead.created)}</div>
                      </TableCell>
                      <TableCell>
                        <div>{lead.enabled ? 'Да' : 'Нет'}</div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          ) : (
            <TableLoadingWrapper>
              <CircularProgress/>
            </TableLoadingWrapper>
          )}
        </TableContainer>
      </TableWrapper>
    </div>
  );
}

export default LeadGeneratorTemplateTable;
