import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Container, Typography } from '@material-ui/core';

import { useShallowEqualSelector } from 'modules/hooks';

import { saveLeadGeneratorTemplate } from 'actions';

import Form from 'components/LeadGeneratorTemplate/Form/Form';
import { FormWrapper } from 'components/Common/Styled/FormWrapper';

import { Dictionaries } from 'types/Dictionaries/Dictionaries';
import {LeadGeneratorTemplate} from "types/LeadGeneratorTemplate/LeadGeneratorTemplate";

function Update() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<LeadGeneratorTemplate>();

  const dictionaries = useShallowEqualSelector<Dictionaries>(({ dictionaries }) => dictionaries);

  const handleSubmit = (lead: LeadGeneratorTemplate) => {
    dispatch(saveLeadGeneratorTemplate(lead));
    history.goBack();
  };

  const getTitle = () => {
    return location?.state
      ? `Изменение lead generator template #${location?.state?.id}`
      : 'Создание  lead generator template';
  };

  return (
    <div>
      <Container maxWidth="sm">
        <FormWrapper>
          <Typography variant="h6">{getTitle()}</Typography>
          <Form dictionaries={dictionaries} handleSubmit={handleSubmit} leadGeneratorTemplate={location.state} />
        </FormWrapper>
      </Container>
    </div>
  );
}

export default Update;
