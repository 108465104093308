/**
 * @module Sagas/Login
 * @desc Login
 */

import {all, delay, put, takeLatest} from 'redux-saga/effects';

import {ActionTypes} from 'literals';
import {StoreAction} from "types";
import {LoginForm} from "types/User/User";
import {call} from "redux-saga-test-plan/matchers";
import {API} from "config/Api.routes";
import {apiRequest} from "sagas/API/API";

/**
 * Login
 */
export function* login({payload}: StoreAction<LoginForm>) {

  try {
    const loginRequest = yield call(apiRequest, API.LOGIN, "POST", payload);

    yield put({
      type: ActionTypes.USER_LOGIN_SUCCESS,
      payload: {
        accessToken: loginRequest.accessToken,
        user: {
          login: payload?.login,
          firstName: payload?.login
        },
        error: null
      }
    });
  } catch (err) {
    yield put({
      type: ActionTypes.USER_LOGIN_FAILURE,
      payload: {
        error: "Проверьте логин или пароль"
      }
    });
  }
}

/**
 * Logout
 */
export function* logout() {
  yield delay(200);

  yield put({
    type: ActionTypes.USER_LOGOUT_SUCCESS,
  });
}

/**
 * Login Sagas
 */
export default function* root() {
  yield all([
    takeLatest(ActionTypes.USER_LOGIN_REQUEST, login),
    takeLatest(ActionTypes.USER_LOGOUT_REQUEST, logout),
  ]);
}
