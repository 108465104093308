import { FormControl, Paper } from '@material-ui/core';
import styled from 'styled-components';

const FormWrapper = styled(Paper)`
  padding: 20px;
  margin-top: 100px;
`;

const FullWidthFormControl = styled(FormControl)`
  width: 100%;
`;

export { FormWrapper, FullWidthFormControl };
