import * as yup from 'yup';

export const LoginformValidationSchema = yup.object({
  login: yup
    .string()
    .required('Необходимо ввести логин'),
  password: yup
    .string()
    .min(4, 'Пароль должен быть более 4-и символов')
    .required('Необходимо ввести пароль'),
});
