import styled from "styled-components";

const DoubleInputFilterSty = styled.div`
  height: 90px;
  display: flex;
  width: 100%;
  min-width: 200px;
  flex-direction: column;
  justify-content: space-between;
`

export {DoubleInputFilterSty}
