import {createReducer} from 'modules/helpers';

import {ActionTypes, STATUS} from 'literals';
import {CommissionRuleState} from "types/CommissionRule/CommissionRule";

export const commissionRuleState: CommissionRuleState = {
  status: STATUS.RUNNING,
  commissionRule: [
    {
      brand: 'SMSFINANCE',
      commission: 0,
      endIssueDate: '',
      id: 123,
      maxAmount: 14,
      minAmount: 12,
      startIssueDate: '',
      utmContent: '',
      leadGenerator: {
        id: 0,
        name: ''
      },
    },
  ],
  perPageViewer: {
    totalPages: 1,
    totalElements: 1,
    numberOfElements: 1,
    pageable: {
      offset: 1,
      pageSize: 25,
      pageNumber: 1,
    },
  },
};

export default {
  commissionRule: createReducer<CommissionRuleState>(
    {
      [ActionTypes.GET_COMMISSION_RULE]: draft => {
        draft.status = STATUS.RUNNING;
      },
      [ActionTypes.GET_COMMISSION_RULE_SUCCESS]: (draft, {payload}) => {
        draft.commissionRule = payload.commissionRule;
        draft.perPageViewer = payload.perPageViewer;
        draft.status = payload.status;
      },
    },
    commissionRuleState,
  ),
};
