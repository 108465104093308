import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Container } from '@material-ui/core';

import Update from 'components/LeadGeneratorTemplate/Update/Update';
import PostbackTable from 'components/Postback/Table/PostbackTable';

function Postback() {
  return (
    <Container maxWidth="xl">
      <Switch>
        <Route children={<Update />} path="/postback_event_message/update" />
        <Route children={<PostbackTable />} path="/postback_event_message" />
      </Switch>
    </Container>
  );
}

export default Postback;
