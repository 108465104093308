import {now} from '@gilbarbara/helpers';
import {all, call, put, select, takeLatest} from 'redux-saga/effects';

import {APILeadsTableRequestParams} from 'config/Api';
import {ActionTypes, STATUS} from 'literals';

import {StoreAction, StoreState} from 'types';
import {FiltersState} from 'types/Filters/FiltersState';
import {LeadGeneratorTemplate, LeadGeneratorTemplateState} from 'types/LeadGeneratorTemplate/LeadGeneratorTemplate';
import {Pageable, PerPageViewer} from 'types/PerPageViewer/PerPageViewer';
import {API} from "config/Api.routes";
import {apiRequest} from "sagas/API/API";
import {cloneDeep} from "lodash";

export interface LeadGeneratorFromTemplateAPI {
  content: LeadGeneratorTemplate[];
  numberOfElements: number;
  pageable: Pageable;
  totalElements: number;
  totalPages: number;
}

function getFilterParams(filters: FiltersState, perPageViewer: PerPageViewer): APILeadsTableRequestParams {
  return {
    brand: filters.leadGeneratorTemplate.brand,
    eventType: filters.leadGeneratorTemplate.eventType,
    pageNo: perPageViewer.pageable.pageNumber,
    pageSize: perPageViewer.pageable.pageSize,
    leadGeneratorId: filters.leadGeneratorTemplate.leadGenerator?.id,
    template: filters.leadGeneratorTemplate.template,
    tariff: filters.leadGeneratorTemplate.tariff,
  }
}

export function* getLeads(action: StoreAction | undefined) {
  try {
    const filters: FiltersState = yield select((s: StoreState) => s.filters);
    const ppw: PerPageViewer = yield select((s: StoreState) => s.perPageViewer);

    const perPageViewer: PerPageViewer = cloneDeep(ppw);

    /**
     * Запрос с перезагрузкой пагинатора
     */
    if (action?.payload?.resetPaginator) {
      perPageViewer.pageable.pageNumber = 0
    }

    const fromApi: LeadGeneratorFromTemplateAPI = yield call(
      apiRequest,
      API.LEAD_GENERATOR_TEMPLATE,
      "GET",
      null,
      getFilterParams(filters, perPageViewer),
    );

    const leadsFromApi: LeadGeneratorTemplateState = {
      leadGeneratorTemplate: fromApi.content,
      perPageViewer: {
        pageable: fromApi.pageable,
        totalPages: fromApi.totalPages,
        totalElements: fromApi.totalElements,
        numberOfElements: fromApi.numberOfElements,
      },
      status: STATUS.SUCCESS,
    };

    yield all([
      put({
        type: ActionTypes.GET_LEAD_GENERATOR_TEMPLATE_SUCCESS,
        payload: leadsFromApi,
        meta: {cached: true, updatedAt: now()},
      }),
      put({
        type: ActionTypes.SET_PER_PAGE_VIEWER,
        payload: leadsFromApi.perPageViewer,
        meta: {cached: true, updatedAt: now()},
      }),
    ]);
  } catch (error) {
    console.error(error);
  }
}

/**
 * Сохранение детальки лида
 * @param action
 */
export function* saveLead(action: StoreAction) {

  const toSave: LeadGeneratorTemplate = {
    id: null,
    brand: '',
    enabled: false,
    eventType: '',
    leadGenerator: {
      id: null,
      name: ''
    },
    template: '',
    tariff: ''
  };

  const names = Object.keys(action.payload);

  names.forEach(value => {
    if (action.payload[value]) {
      toSave[value] = action.payload[value];
    }
  });

  // @ts-ignore
  const fromApi: any = yield call(apiRequest, API.LEAD_GENERATOR_TEMPLATE, "POST", toSave);

  if (fromApi) {
    yield all([
      call(getLeads, action),
      put({
        type: ActionTypes.LEAD_GENERATOR_TEMPLATE_SAVED
      })
    ])
  }

}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.GET_LEAD_GENERATOR_TEMPLATE, getLeads),
    takeLatest(ActionTypes.SAVE_LEAD_GENERATOR_TEMPLATE, saveLead),
  ]);
}
