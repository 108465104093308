import { createAction } from 'modules/helpers';

import { ActionTypes } from 'literals';

/**
 * Фильтры таблицы лидогенераторов
 */
export const setPostbackBrandFilter = createAction(
  ActionTypes.SET_BRAND_TO_POSTBACK_FILTER,
  (brand: string) => ({ brand }),
);
export const setPostbackEventTypeFilter = createAction(
  ActionTypes.SET_EVENT_TYPE_TO_POSTBACK_FILTER,
  (eventType: string) => ({ eventType }),
);
export const setPostbackEnabledFilter = createAction(
  ActionTypes.SET_ENABLED_TO_POSTBACK_FILTER,
  (enabled: string) => ({ enabled }),
);
