import { createAction } from 'modules/helpers';

import { ActionTypes } from 'literals';

import { PerPageViewer } from 'types/PerPageViewer/PerPageViewer';

export const setPerPageViewer = createAction(
  ActionTypes.SET_PER_PAGE_VIEWER,
  (perPageViewer: PerPageViewer) => perPageViewer,
);
