import dictionaries, {dictionariesState} from 'reducers/Dictionaries';
import filters, {filtersState} from 'reducers/Filters/Filters';
import leadGeneratorTemplate, {leadGeneratorTemplateState} from 'reducers/LeadGeneratorTemplate/LeadGeneratorTemplate';
import leadGenerator, {leadGeneratorState} from 'reducers/LeadGenerator/LeadGenerator';
import perPageViewer, {perPageViewerState} from 'reducers/PerPageViewer/PerPageViewer';
import postback, {postbackState} from 'reducers/Postback/Postback';

import app, {appState} from './app';
import login, {loginState} from './Login/Login';
import user, {userState} from "reducers/User/User";
import commissionRule, {commissionRuleState} from "reducers/CommissionRule/CommissionRule";

export const initialState = {
  app: appState,
  login: loginState,
  dictionaries: dictionariesState,
  filters: filtersState,

  perPageViewer: perPageViewerState,
  leadGeneratorTemplate: leadGeneratorTemplateState,
  leadGenerator: leadGeneratorState,

  postback: postbackState,

  user: userState,
  commissionRule: commissionRuleState
};

export default {
  ...app,
  ...login,
  ...user,
  ...dictionaries,
  ...filters,
  ...perPageViewer,

  ...leadGeneratorTemplate,
  ...postback,
  ...commissionRule,
  ...leadGenerator
};
