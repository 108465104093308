import { createReducer } from 'modules/helpers';

import { ActionTypes, STATUS } from 'literals';
import {LoginState} from "types/User/User";

export const loginState: LoginState = {
  error: null,
  status: STATUS.IDLE,
};

export default {
  login: createReducer<LoginState>(
    {
      [ActionTypes.USER_LOGIN_REQUEST]: (draft) => {
        draft.status = STATUS.RUNNING;
      },
      [ActionTypes.USER_LOGIN_SUCCESS]: (draft, payload) => {
        draft.status = STATUS.READY;
      },
      [ActionTypes.USER_LOGIN_FAILURE]: (draft, payload) => {
        draft.error = payload.payload.error;
        draft.status = STATUS.ERROR;
      },
      [ActionTypes.USER_LOGOUT_REQUEST]: draft => {
        draft.status = STATUS.RUNNING;
      },
      [ActionTypes.USER_LOGOUT_SUCCESS]: draft => {
        draft.status = STATUS.IDLE;
      },
    },
    loginState,
  ),
};
