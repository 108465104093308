import React, {useEffect, useState} from 'react';
import {Button, CircularProgress, Grid, Modal, TextField, Typography,} from '@material-ui/core';
import {CancelButton, ModalButtonsWrapper, ModalWrapper,} from 'components/Common/Styled/ModalWrapper';
import {LoginCardTitle} from 'components/Styled/LoginWrappers';
import {Cancel, Save} from "@material-ui/icons";
import {LeadGenerator} from "types/LeadGenerator/LeadGenerator";

interface FormUpdateProp {
  leadGenerator: LeadGenerator;

  handleSubmit(lead: LeadGenerator): void;
}

const mock: LeadGenerator = {
  id: 0,
  name: ""
};

function Form({handleSubmit, leadGenerator}: FormUpdateProp) {
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [leadCopy, setLeadCopy] = useState<LeadGenerator>(leadGenerator || mock);

  useEffect(() => {
    setLeadCopy(leadGenerator || mock);
  }, [leadGenerator]);

  const saveLeadGeneratorTemplate = () => {
    handleSubmit(leadCopy);
    setLoading(true);
    // history.push('lidogen')
  };

  const setLead = (lead: any) => {
    setLeadCopy({...leadCopy, ...lead});
  };

  const handleModalState = (value: boolean) => {
    setModal(value);
  };

  return (
    <div>
      <Grid
        alignItems="center"
        container
        direction="column"
        justifyContent="center"
        spacing={3}
        style={{marginTop: '30px'}}
      >

        <Grid item style={{width: '100%'}} xs={12}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Name"
            onChange={e => setLead({name: e.target.value as string})}
            value={leadCopy.name || ''}
            variant="outlined"
          />
        </Grid>

        <Grid item style={{width: '100%'}} xs={12}>
          <Button
            color="primary"
            disabled={modal}
            fullWidth
            onClick={() => handleModalState(true)}
            size="large"
            variant="contained"
          >
            Сохранить
          </Button>
        </Grid>
      </Grid>

      <Modal
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        onClose={() => handleModalState(false)}
        open={modal}
      >
        <ModalWrapper>
          <LoginCardTitle>
            <Typography component="h2" variant="h5">
              Сохранить lead generator ?!
            </Typography>
          </LoginCardTitle>
          <ModalButtonsWrapper>
            <Button
              color="primary"
              disabled={loading}
              fullWidth
              onClick={saveLeadGeneratorTemplate}
              size="large"
              startIcon={!loading && <Save/>}
              variant="contained"
            >
              {loading ? <CircularProgress size={32}/> : 'Сохранить'}
            </Button>
            <CancelButton
              color="secondary"
              disabled={loading}
              fullWidth
              onClick={() => setModal(false)}
              size="large"
              startIcon={<Cancel/>}
              variant="contained"
            >
              Отмена
            </CancelButton>
          </ModalButtonsWrapper>
        </ModalWrapper>
      </Modal>
    </div>
  );
}

export default Form;
