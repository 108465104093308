import React from 'react';
import { Container } from '@material-ui/core';

function Private() {
  return (
    <Container>
      <h1 />
    </Container>
  );
}

export default Private;
