import React from 'react';
import {Divider, InputLabel, MenuItem, Select} from '@material-ui/core';

import {FilteredTableControl} from 'components/Common/Table/Styled/FilterWrapper';

export interface IBrandSelectorProps {
  current: object | string;
  dictionaries: object[] | string[];
  stack: string[];
  title: string;
  setNameFromObject?: string

  dispatcher(stack: string[], filter: string | boolean | object): void;
}

/**
 *
 * @param dictionaries Список справочников - справочники для выбора
 * @param current Текущий выбранный справочник - выбранный справочник из dictionaries
 * @param title Заголовок - если справочник не выбран
 * @param dispatcher Диспатчер - принимает функцию экшона, для применения фильтра
 * @param stack Стэк - задаёт наименование для поля в объекте (нужно будет немного изменить логику заполнения)
 * @param setNameFromObject Это нужно, если справочник является объектом, чтобы выдернуть из него строку,
 * так как у нас сейчас есть справочники только текстовые, нужно будет переписать моки справочников под единый формат объекта
 * @param setIdFiledFromObject Поле для того, чтобы определить ключ в объекте
 * @constructor
 */
function DictionarySelector({
                              current,
                              dictionaries,
                              dispatcher,
                              stack,
                              title,
                              setNameFromObject = undefined
                            }: IBrandSelectorProps) {

  const onClick = (index: number) => {
      dispatcher(stack, dictionaries[index]);
  };


  return (
    <FilteredTableControl variant="outlined">
      <InputLabel id="demo-simple-select-outlined-label">{title}</InputLabel>
      <Select
        id="demo-simple-select-outlined"
        label={title}
        labelId="demo-simple-select-outlined-label"
        onChange={e => onClick(e.target.value as number)}
        value={
          // @ts-ignore
          dictionaries.indexOf(current) > -1 ? dictionaries.indexOf(current) : ''
        }
      >
        <MenuItem value={-1}>
          <em>None</em>
        </MenuItem>
        <Divider/>
        {dictionaries.map((b, index) => (
          <MenuItem key={index} value={index}>
            {
              setNameFromObject ?  b[setNameFromObject] : b
            }
          </MenuItem>
          )
        )}
      </Select>
    </FilteredTableControl>
  );
}

export {DictionarySelector};
