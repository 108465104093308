import { createReducer } from 'modules/helpers';

import { ActionTypes } from 'literals';

import { FiltersState } from 'types/Filters/FiltersState';

export const filtersState: FiltersState = {
  leadGenerator: {
    id: '',
    name: ''
  },
  leadGeneratorTemplate: {
    brand: '',
    eventType: '',
    enabled: '',
    leadGenerator: {
      id: '',
      name: ''
    },
    template: '',
    tariff: ''
  },
  postback: {
    brand: '',
    commission: '',
    crmClientId: '',
    crmClientLimit: '',
    dispatchRetry: '',
    dispatchState: '',
    startEventOccurred: '',
    finishEventOccurred: '',
    eventType: '',
    statusMessage: '',
  },
  commissionRule: {
    commission: '',
    endIssueDate: '',
    maxAmount: '',
    minAmount: '',
    startIssueDate: '',
    utmContent: '',
    utmSource: '',
  }
};

export default {
  filters: createReducer<FiltersState>(
    {
      [ActionTypes.SET_FILTERS]: (draft, { payload }) => {
        console.log(payload)
        draft.postback = { ...draft.postback, ...payload.filters.postback };
        draft.leadGeneratorTemplate = { ...draft.leadGeneratorTemplate, ...payload.filters.leadGeneratorTemplate };
        draft.commissionRule = { ...draft.commissionRule, ...payload.filters.commissionRule };
      },
    },
    filtersState,
  ),
};
