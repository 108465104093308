import { Card } from '@material-ui/core';
import styled from 'styled-components';

import { theme } from 'modules/theme';

const LoginCard = styled(Card)`
  padding: ${theme.padding};
  width: 20rem;
  box-shadow: ${theme?.shadow?.regular};
  border-radius: ${theme.border?.radius} !important;
  border: none !important;
`;

const LoginCardTitle = styled.div`
  padding: 0 0 20px 0;
  width: 100%;
  text-align: center;
`;

const LoginWrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export { LoginCard, LoginWrapper, LoginCardTitle };
