import {createReducer} from 'modules/helpers';

import {ActionTypes, STATUS} from 'literals';
import {LeadGeneratorState} from "types/LeadGenerator/LeadGenerator";

export const leadGeneratorState: LeadGeneratorState = {
  status: STATUS.RUNNING,
  leadGenerator: [
    {
      id: 72,
      name: "someName"
    },
  ],
  perPageViewer: {
    totalPages: 1,
    totalElements: 1,
    numberOfElements: 1,
    pageable: {
      offset: 1,
      pageSize: 25,
      pageNumber: 1,
    },
  },
};

export default {
  leadGenerator: createReducer<LeadGeneratorState>(
    {
      [ActionTypes.GET_LEAD_GENERATOR]: draft => {
        draft.status = STATUS.RUNNING;
      },
      [ActionTypes.GET_LEAD_GENERATOR_SUCCESS]: (draft, {payload}) => {
        draft.leadGenerator = payload.leadGenerator;
        draft.perPageViewer = payload.perPageViewer;
        draft.status = payload.status;
      },
    },
    leadGeneratorState,
  ),
};
