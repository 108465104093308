/**
 * @module Sagas/ApiRequest
 * @desc ApiRequest
 */

import {all, call, delay, put, select, takeLatest} from 'redux-saga/effects';

import {ActionTypes} from 'literals';
import {API} from "config/Api.routes";
import {apiConfig} from "config/Api";
import {request} from "@gilbarbara/helpers";
import {HttpMethods} from "@gilbarbara/helpers/src/types";

/**
 * Запрос к API
 * @param path
 * @param method
 * @param params
 * @param body
 */
export function* apiRequest(path: API, method: HttpMethods, body: any, params: Object | null = null): any {

  try {
    const token: string = yield select((state) => state.user.accessToken)

    if (!token && path != API.LOGIN) {
      yield delay(200);

      yield put({
        type: ActionTypes.USER_LOGOUT_SUCCESS,
      });
    }
    // @ts-ignore
    return yield call(request,
      apiConfig.url + path + prepareParams(params),
      {
        method: method,
        body: body,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Authorization": `Bearer ${token}`
        },
      }
    );

  } catch (err) {
    if (err.status === 401) {
      yield put({
        type: ActionTypes.USER_LOGOUT_SUCCESS,
      })
    }
  }

}

/**
 * Подготовка параметров в URL
 * @param params
 */
const prepareParams = (params: object | null = null): string => {
  console.log('params', params)
  if (!params)
    return ''

  const names: string[] = Object.keys(params);

  // отсекаем ненужные параметры объекта по длине
  // @ts-ignore
  const p = names.map(n => {
    if (params[n]?.toString().length > 0)
      return `${n}=${params[n]}`
  }).filter(str => str != undefined); // используя map незаполненные поля остаются undefined

  return `?${p.join('&')}`;
}


/**
 * Login Sagas
 */
export default function* root() {
  yield all([
    // @ts-ignore
    takeLatest("API_REQUEST", apiRequest),
  ]);
}
