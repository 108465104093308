import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {Container} from '@material-ui/core';

import LeadGeneratorTemplateTable from 'components/LeadGeneratorTemplate/Table/LeadGeneratorTemplateTable';
import Update from 'components/LeadGeneratorTemplate/Update/Update';

function LeadGeneratorTemplate() {
  return (
    <Container maxWidth="xl">
      <Switch>
        <Route children={<Update />} path="/lead_generator_template/update" />
        <Route children={<LeadGeneratorTemplateTable />} path="/lead_generator_template" />
      </Switch>
    </Container>
  );
}

export default LeadGeneratorTemplate;
