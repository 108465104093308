import {createReducer} from 'modules/helpers';

import {ActionTypes} from 'literals';
import {UserState} from "types/User/User";

export const userState: UserState = {
  accessToken: null,
  user: {
    login: "",
    firstName: ""
  },
  isAuthenticated: false,
};

export default {
  user: createReducer<UserState>(
    {
      [ActionTypes.USER_LOGIN_SUCCESS]: (draft, payload) => {
        draft.accessToken = payload.payload.accessToken
        draft.user = payload.payload.user
        draft.isAuthenticated = true
      },
      [ActionTypes.USER_LOGOUT_SUCCESS]: draft => {
        draft.isAuthenticated = false;
        draft.user = null
        draft.accessToken = null;
      },
    },
    userState,
  ),
};
