import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Container } from '@material-ui/core';

import Update from 'components/CommissionRule/Update/Update';
import CommissionRuleTable from "components/CommissionRule/Table/CommissionRuleTable";

function CommissionRule() {
  return (
    <Container maxWidth="xl">
      <Switch>
        <Route children={<Update />} path="/postback_commission_rule/update" />
        <Route children={<CommissionRuleTable />} path="/postback_commission_rule" />
      </Switch>
    </Container>
  );
}

export default CommissionRule;
