import React from 'react';
import {useDispatch} from 'react-redux';
import {useMount} from 'react-use';
import {
  CircularProgress,
  Fab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import {useShallowEqualSelector} from 'modules/hooks';

import {STATUS} from 'literals';

import {getDictionaries, setFilters} from 'actions';
import {getPostback} from 'actions/Postback';

import {InputFilter} from 'components/Filters/Input/InputFilter';
import {DictionarySelector} from 'components/Filters/Selectors/DictionarySelector';
import {PerPageSelector} from 'components/PerPageSelector/PerPageSelector';
import {
  TableControls,
  TableLoadingWrapper,
  TableWrapper,
} from 'components/Common/Table/Styled/TableWrapper';

import {Dictionaries} from 'types/Dictionaries/Dictionaries';
import {FiltersState} from 'types/Filters/FiltersState';
import {PerPageViewer} from 'types/PerPageViewer/PerPageViewer';
import {PostbackState} from 'types/Postback/Postback';
import {DateTimeFilter} from "components/Filters/DateTime/DateTimeFilter";

import {DoubleInputFilterSty} from "components/Filters/DateTime/Styled/DoublieInputFilterSty";
import {CloudDownload} from "@material-ui/icons";
import {getFormatedDate} from "utils/helpers";

function PostbackTable() {
  const filters = useShallowEqualSelector<FiltersState>(({filters}) => filters);
  const perPageViewer = useShallowEqualSelector<PerPageViewer>(
    ({perPageViewer}) => perPageViewer,
  );
  const dictionaries = useShallowEqualSelector<Dictionaries>(({dictionaries}) => dictionaries);

  const leadState: PostbackState = useShallowEqualSelector<PostbackState>(
    ({postback}) => postback,
  );


  const dispatch = useDispatch();

  useMount(() => {
    dispatch(getDictionaries());
    dispatch(getPostback({resetPaginator: true}));
  });

  const useFilter = (stack: string[], value: string | boolean) => {
    const save = {
      [stack[0]]: {
        [stack[1]]: value,
      },
    };

    dispatch(setFilters({...filters, ...save}));
    dispatch(getPostback());
  };

  return (
    <div>
      <TableWrapper>
        {leadState.status != STATUS.SUCCESS || (
          <TableControls>
            <Fab aria-label="add" color="primary" size="small">
              <CloudDownload/>
            </Fab>
            <PerPageSelector actionDispatcher={getPostback} perPageViewer={perPageViewer}/>
          </TableControls>
        )}

        <TableContainer>
          {leadState.status === STATUS.SUCCESS ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>
                    <DictionarySelector
                      current={filters.postback.brand}
                      dictionaries={dictionaries.brands}
                      dispatcher={useFilter}
                      stack={['postback', 'brand']}
                      title="Brand"
                    />
                  </TableCell>
                  <TableCell>
                    <InputFilter
                      current={filters.postback.commission}
                      dispatcher={useFilter}
                      stack={['postback', 'commission']}
                      title="Commission"
                      disabled={true}
                    />
                  </TableCell>
                  <TableCell>
                    <InputFilter
                      current={filters.postback.crmClientId}
                      dispatcher={useFilter}
                      stack={['postback', 'crmClientId']}
                      title="Client Id"
                    />
                  </TableCell>
                  <TableCell>
                    <InputFilter
                      current={filters.postback.crmClientLimit}
                      dispatcher={useFilter}
                      stack={['postback', 'crmClientLimit']}
                      title="Client limit"
                      disabled={true}
                    />
                  </TableCell>

                  <TableCell>
                    <InputFilter
                      current={filters.postback.dispatchRetry}
                      dispatcher={useFilter}
                      stack={['postback', 'dispatchRetry']}
                      title="Dispatch retry"
                      disabled={true}
                    />
                  </TableCell>

                  <TableCell>
                    <InputFilter
                      current={filters.postback.dispatchState}
                      dispatcher={useFilter}
                      stack={['postback', 'dispatchState']}
                      title="Dispatch state"
                    />
                  </TableCell>

                  <TableCell>
                    <DoubleInputFilterSty>
                      <DateTimeFilter
                        size="small"
                        current={filters.postback.startEventOccurred}
                        dispatcher={useFilter}
                        stack={['postback', 'startEventOccurred']}
                        title="Start EventOccurred"
                      />
                      <DateTimeFilter
                        size="small"
                        current={filters.postback.finishEventOccurred}
                        dispatcher={useFilter}
                        stack={['postback', 'finishEventOccurred']}
                        title="Finish EventOccurred"
                      />
                    </DoubleInputFilterSty>
                  </TableCell>
                  <TableCell>
                    <DictionarySelector
                      current={filters.postback.eventType}
                      dictionaries={dictionaries.eventTypes}
                      dispatcher={useFilter}
                      stack={['postback', 'eventType']}
                      title="Event type"
                    />
                  </TableCell>
                  <TableCell>
                    <InputFilter
                      current={filters.postback.statusMessage}
                      dispatcher={useFilter}
                      stack={['postback', 'statusMessage']}
                      title="Status message"
                      disabled={true}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leadState.postback.map(postback => {
                  return (
                    <TableRow
                      key={postback.id}
                      hover
                      style={{
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                      }}
                    >
                      <TableCell>
                        <div>{postback.id}</div>
                      </TableCell>
                      <TableCell
                        style={{
                          maxWidth: 200,
                        }}
                      >
                        <div>{postback.brand}</div>
                      </TableCell>
                      <TableCell>
                        <div>{postback.commission}</div>
                      </TableCell>
                      <TableCell>
                        <div>{postback.crmClientId}</div>
                      </TableCell>
                      <TableCell>
                        <div>{postback.crmClientLimit}</div>
                      </TableCell>
                      <TableCell>
                        <div>{postback.dispatchRetry}</div>
                      </TableCell>
                      <TableCell>
                        <div>{postback.dispatchState}</div>
                      </TableCell>
                      <TableCell>
                        <div>{getFormatedDate(postback.eventOccurred)}</div>
                      </TableCell>
                      <TableCell>
                        <div>{postback.eventType}</div>
                      </TableCell>
                      <TableCell>
                        <div>{postback.statusMessage}</div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          ) : (
            <TableLoadingWrapper>
              <CircularProgress/>
            </TableLoadingWrapper>
          )}
        </TableContainer>
      </TableWrapper>
    </div>
  );
}

export default PostbackTable;
