import React from 'react';
import ReactDOM from 'react-dom';
import {HelmetProvider} from 'react-helmet-async';
import {Provider} from 'react-redux';
import {createTheme} from '@material-ui/core';
import {ruRU} from '@material-ui/core/locale';
import {PersistGate} from 'redux-persist/lib/integration/react';
import {register} from 'serviceWorkerRegistration';
import {configStore} from 'store';
import {ThemeProvider} from 'styled-components';

import GlobalStyles from 'components/Styled/GlobalStyles';
import ErrorHandler from 'containers/ErrorHandler';

import reportWebVitals from './reportWebVitals';
import Root from './Root';
import {Store} from "redux";
import moment from "moment-timezone"

moment.tz.setDefault("Etc/UTC")

const {persistor, store} = configStore();
const theme = createTheme(
  {
    palette: {
      primary: {main: '#1e1e1e'},
    },
  },
  ruRU,
);

declare global {
  interface Window {
    store: Store
  }
}

window.store = store;
// <PersistGate loading={<Loader block size={100} />}
ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ErrorHandler>
        <HelmetProvider>
          <ThemeProvider theme={theme}>
            <Root/>
          </ThemeProvider>
        </HelmetProvider>
      </ErrorHandler>
      <GlobalStyles/>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

/* istanbul ignore next */
register({
  onUpdate: () => {
    // store.dispatch(showAlert(<Reload />, { id: 'sw-update', icon: 'bolt', timeout: 0 }));
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log); // eslint-disable-line no-console
