interface Route {
  path: string;
  title: string;
}

const routes: Route[] = [
  {
    path: 'lead_generator_template',
    title: 'Lead generator template',
  },
  {
    path: 'postback_event_message',
    title: 'Postback event message',
  },
  {
    path: 'postback_commission_rule',
    title: 'Postback commission rule'
  },
  {
    path: 'lead_generator',
    title: 'Lead generator'
  }
];

const getTitle = (path: string): string => {
  return routes.find(route => path.includes(route.path))!.title || '';
};

const isActive = (path: string, location: string): boolean => {
  return location === path;
};

export { routes, getTitle, isActive };
