import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {Container} from '@material-ui/core';
import LeadGeneratorTable from "components/LeadGenerator/Table/LeadGeneratorTable";
import Update from "components/LeadGenerator/Update/Update";

function LeadGenerator() {
  return (
    <Container maxWidth="xl">
      <Switch>
        <Route children={<Update />} path="/lead_generator/update" />
        <Route children={<LeadGeneratorTable/>} path="/lead_generator"/>
      </Switch>
    </Container>
  );
}

export default LeadGenerator;
