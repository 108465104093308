import { createReducer } from 'modules/helpers';

import { ActionTypes, STATUS } from 'literals';

import { PostbackState } from 'types/Postback/Postback';

export const postbackState: PostbackState = {
  status: STATUS.RUNNING,
  postback: [
    {
      id: 1,
      rawBody: 'iudhfviudfnikujbnkj',
      raw: null,
      messageProperties: 'juvisdndivjunsdjik',
      alternativeKey: null,
      dispatchState: 'ERROR',
      dispatchRetry: 7467,
      eventType: 'REGISTERED',
      brand: 'VIVUS',
      crmClientId: '200720857679689',
      metadata: null,
    },
  ],
  perPageViewer: {
    totalPages: 1,
    totalElements: 1,
    numberOfElements: 1,
    pageable: {
      offset: 1,
      pageSize: 25,
      pageNumber: 1,
    },
  },
};

export default {
  postback: createReducer<PostbackState>(
    {
      [ActionTypes.GET_POSTBACK]: draft => {
        draft.status = STATUS.RUNNING;
      },
      [ActionTypes.GET_POSTBACK_SUCCESS]: (draft, { payload }) => {
        draft.postback = payload.postback;
        draft.perPageViewer = payload.perPageViewer;
        draft.status = payload.status;
      },
    },
    postbackState,
  ),
};
