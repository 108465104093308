import {keyMirror} from '@gilbarbara/helpers';

import {DictionaryActionTypes} from 'literals/Dictionaries';
import {PerPageViewerActionTypes} from 'literals/PerPageViewer/PerPageViewer';

import {FilterActionTypes} from 'actions/Filters/FilterActionTypes';
import {PostbackActionTypes} from 'actions/Postback/PostbackActionTypes';

import {Status} from 'types';
import {CommissionRuleActionTypes} from "actions/CommissionRule/ActionTypes";
import {leadGeneratorTemplateActionTypes} from "actions/LeadGeneratorTemplate/ActionTypes";
import {leadGeneratorActionTypes} from "actions/LeadGenerator/ActionTypes";

export const ActionTypes = keyMirror({
  HIDE_ALERT: undefined,
  SHOW_ALERT: undefined,
  USER_LOGIN_REQUEST: undefined,
  USER_LOGIN_SUCCESS: undefined,
  USER_LOGIN_FAILURE: undefined,
  USER_LOGOUT_REQUEST: undefined,
  USER_LOGOUT_SUCCESS: undefined,
  USER_LOGOUT_FAILURE: undefined,

  ...DictionaryActionTypes,
  ...leadGeneratorTemplateActionTypes,
  ...PerPageViewerActionTypes,
  ...PostbackActionTypes,
  ...FilterActionTypes,
  ...CommissionRuleActionTypes,
  ...leadGeneratorActionTypes
});

export const STATUS: Status = {
  IDLE: 'idle',
  RUNNING: 'running',
  READY: 'ready',
  SUCCESS: 'success',
  ERROR: 'error',
};
