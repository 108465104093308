import { createAction } from 'modules/helpers';

import { ActionTypes } from 'literals';

import { FiltersState } from 'types/Filters/FiltersState';

/**
 * Фильтры таблицы лидогенераторов
 */
export const setFilters = createAction(ActionTypes.SET_FILTERS, (filters: FiltersState) => ({
  filters,
}));
