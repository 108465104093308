import React, {useState} from 'react';
import {
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';

import {FullWidthFormControl} from 'components/Common/Styled/FormWrapper';
import {CancelButton, ModalButtonsWrapper, ModalWrapper,} from 'components/Common/Styled/ModalWrapper';
import {LoginCardTitle} from 'components/Styled/LoginWrappers';
import {CommissionRule} from "types/CommissionRule/CommissionRule";
import {Cancel, Save} from "@material-ui/icons";
import {DateTimePicker} from "components/Layout/Input/DateTimePicker/DateTimePicker";
import {findIndex} from "lodash";
import {Dictionaries} from "types/Dictionaries/Dictionaries";

interface FormUpdateProp {
  commissionRule: CommissionRule;
  dictionaries: Dictionaries,

  handleSubmit(commissionRule: CommissionRule): void;
}

const mock: CommissionRule = {
  commission: 0,
  endIssueDate: "",
  maxAmount: 0,
  minAmount: 0,
  startIssueDate: "",
  utmContent: "",
  leadGenerator: {
    id: 0,
    name: ''
  },
  id: 0,
};

function Form({dictionaries, handleSubmit, commissionRule}: FormUpdateProp) {
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [commissionRuleCopy, setCommissionRuleCopy] = useState<CommissionRule>(commissionRule || mock);

  const saveCommissionRule = () => {
    handleSubmit(commissionRuleCopy);
    setLoading(true);
  };

  const setCommissionRule = (lead: any) => {
    setCommissionRuleCopy({...commissionRuleCopy, ...lead});
  };

  const handleModalState = (value: boolean) => {
    setModal(value);
  };

  return (
    <div>
      <Grid
        alignItems="center"
        container
        direction="column"
        justifyContent="center"
        spacing={3}
        style={{marginTop: '30px'}}
      >
        <Grid item style={{width: '100%'}} xs={12}>
          <FullWidthFormControl variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">Бренд</InputLabel>
            <Select
              label="Бренд"
              onChange={e => setCommissionRule({brand: e.target.value as string})}
              value={commissionRuleCopy.brand || ''}
            >
              <MenuItem disabled value="">
                None
              </MenuItem>
              {dictionaries.brands.map((b, index) => {
                return (
                  <MenuItem key={index} value={b}>
                    {b}
                  </MenuItem>
                );
              })}
            </Select>
          </FullWidthFormControl>
        </Grid>

        <Grid item style={{width: '100%'}} xs={12}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="commission"
            type="number"
            onChange={e => setCommissionRule({commission: e.target.value as string})}
            value={commissionRuleCopy.commission || ''}
            variant="outlined"
          />
        </Grid>

        <Grid item style={{width: '100%'}} xs={12}>
          <FullWidthFormControl variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">Lead generator</InputLabel>
            <Select
              label="Lead generator"
              onChange={e => setCommissionRule({leadGenerator: dictionaries.leadGenerator[e.target.value as number]})}
              value={
                findIndex(dictionaries.leadGenerator, commissionRuleCopy.leadGenerator) > -1 ?
                  findIndex(dictionaries.leadGenerator, commissionRuleCopy.leadGenerator) : ''
              }
            >
              <MenuItem value="">
                None
              </MenuItem>
              {dictionaries.leadGenerator.map((b, index) => {
                return (
                  <MenuItem key={index} value={index}>
                    {b.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FullWidthFormControl>
        </Grid>

        <Grid item style={{width: '100%'}} xs={12}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="utmContent"
            onChange={e => setCommissionRule({utmContent: e.target.value as string})}
            value={commissionRuleCopy.utmContent || ''}
            variant="outlined"
          />
        </Grid>

        <Grid item style={{width: '100%'}} xs={12}>
          <FullWidthFormControl>
            <DateTimePicker
              title="startIssueDate"
              onChange={e => setCommissionRule({startIssueDate: e.target.value as string})}
              value={commissionRuleCopy.startIssueDate}
            />
          </FullWidthFormControl>
        </Grid>

        <Grid item style={{width: '100%'}} xs={12}>
          <FullWidthFormControl>
            <DateTimePicker
              title="endIssueDate"
              onChange={e => setCommissionRule({endIssueDate: e.target.value as string})}
              value={commissionRuleCopy.endIssueDate || ''}
            />
          </FullWidthFormControl>
        </Grid>

        <Grid item style={{width: '100%'}} xs={12}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="minAmount"
            type="number"
            onChange={e => setCommissionRule({minAmount: e.target.value as string})}
            value={commissionRuleCopy.minAmount || ''}
            variant="outlined"
          />
        </Grid>

        <Grid item style={{width: '100%'}} xs={12}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="maxAmount"
            type="number"
            onChange={e => setCommissionRule({maxAmount: e.target.value as string})}
            value={commissionRuleCopy.maxAmount || ''}
            variant="outlined"
          />
        </Grid>

        <Grid item style={{width: '100%'}} xs={12}>
          <Button
            color="primary"
            disabled={modal}
            fullWidth
            onClick={() => handleModalState(true)}
            size="large"
            variant="contained"
          >
            Сохранить
          </Button>
        </Grid>
      </Grid>

      <Modal
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        onClose={() => handleModalState(false)}
        open={modal}
      >
        <ModalWrapper>
          <LoginCardTitle>
            <Typography component="h2" variant="h5">
              Вы уверены, что хотите сохранить commission rule ?!
            </Typography>
          </LoginCardTitle>
          <ModalButtonsWrapper>
            <Button
              color="primary"
              disabled={loading}
              fullWidth
              onClick={saveCommissionRule}
              size="large"
              startIcon={!loading && <Save/>}
              variant="contained"
            >
              {loading ? <CircularProgress size={32}/> : 'Сохранить'}
            </Button>
            <CancelButton
              color="secondary"
              disabled={loading}
              fullWidth
              onClick={() => setModal(false)}
              size="large"
              startIcon={<Cancel/>}
              variant="contained"
            >
              Отмена
            </CancelButton>
          </ModalButtonsWrapper>
        </ModalWrapper>
      </Modal>
    </div>
  );
}

export default Form;
