import { LeadsFromAPI } from 'sagas/LeadGeneratorTemplate/LeadGeneratorTemplate';
import { PostbackFromAPI } from 'sagas/Postback/Postback';

export const brandDictionaryMock: string[] = ['SMSFINANCE', 'VIVUS', 'CONTACTCREDIT', 'OVERDRAFT'];

export const eventTypeDictionaryMock: string[] = ['REGISTERED', 'LOAN_ISSUED'];

export const enabledDictionaryMock: string[] = ['Да', 'Нет'];

export const leadsMock: LeadsFromAPI = {
  content: [
    {
      id: 1,
      brand: 'SMSFINANCE',
      eventType: 'REGISTERED',
      source: 'unicom24',
      template: 'https://unicom24.ru/offer/postback/{click_id}/?status=receive',
      created: '2019-12-16T11:52:03.977+00:00',
      enabled: true,
    },
    {
      id: 2,
      brand: 'SMSFINANCE',
      eventType: 'REGISTERED',
      source: 'unicom24',
      template: 'https://unicom24.ru/offer/postback/{click_id}/?status=receive',
      created: '2019-12-16T11:52:03.977+00:00',
      enabled: true,
    },
    {
      id: 3,
      brand: 'SMSFINANCE',
      eventType: 'REGISTERED',
      source: 'unicom24',
      template: 'https://unicom24.ru/offer/postback/{click_id}/?status=receive',
      created: '2019-12-16T11:52:03.977+00:00',
      enabled: true,
    },
  ],
  totalPages: 1,
  totalElements: 3,
  numberOfElements: 3,
  pageable: {
    offset: 1,
    pageSize: 25,
    pageNumber: 1,
  },
};

export const postbackMoks: PostbackFromAPI = {
  content: [
    {
      id: 1,
      rawBody: 'iudhfviudfnikujbnkj',
      raw: null,
      messageProperties: 'juvisdndivjunsdjik',
      alternativeKey: null,
      dispatchState: 'ERROR',
      dispatchRetry: 7467,
      eventType: 'REGISTERED',
      brand: 'VIVUS',
      crmClientId: '200720857679689',
      metadata: null,
    },
    {
      id: 2,
      rawBody: 'iudhfviudfnikujbnkj',
      raw: null,
      messageProperties: 'juvisdndivjunsdjik',
      alternativeKey: null,
      dispatchState: 'ERROR',
      dispatchRetry: 7467,
      eventType: 'REGISTERED',
      brand: 'VIVUS',
      crmClientId: '200720857679689',
      metadata: null,
    },
    {
      id: 3,
      rawBody: 'iudhfviudfnikujbnkj',
      raw: null,
      messageProperties: 'juvisdndivjunsdjik',
      alternativeKey: null,
      dispatchState: 'ERROR',
      dispatchRetry: 7467,
      eventType: 'REGISTERED',
      brand: 'VIVUS',
      crmClientId: '200720857679689',
      metadata: null,
    },
    {
      id: 4,
      rawBody: 'iudhfviudfnikujbnkj',
      raw: null,
      messageProperties: 'juvisdndivjunsdjik',
      alternativeKey: null,
      dispatchState: 'ERROR',
      dispatchRetry: 7467,
      eventType: 'REGISTERED',
      brand: 'VIVUS',
      crmClientId: '200720857679689',
      metadata: null,
    },
    {
      id: 5,
      rawBody: 'iudhfviudfnikujbnkj',
      raw: null,
      messageProperties: 'juvisdndivjunsdjik',
      alternativeKey: null,
      dispatchState: 'ERROR',
      dispatchRetry: 7467,
      eventType: 'REGISTERED',
      brand: 'VIVUS',
      crmClientId: '200720857679689',
      metadata: null,
    },
    {
      id: 6,
      rawBody: 'iudhfviudfnikujbnkj',
      raw: null,
      messageProperties: 'juvisdndivjunsdjik',
      alternativeKey: null,
      dispatchState: 'ERROR',
      dispatchRetry: 7467,
      eventType: 'REGISTERED',
      brand: 'VIVUS',
      crmClientId: '200720857679689',
      metadata: null,
    },
    {
      id: 7,
      rawBody: 'iudhfviudfnikujbnkj',
      raw: null,
      messageProperties: 'juvisdndivjunsdjik',
      alternativeKey: null,
      dispatchState: 'ERROR',
      dispatchRetry: 7467,
      eventType: 'REGISTERED',
      brand: 'VIVUS',
      crmClientId: '200720857679689',
      metadata: null,
    },
    {
      id: 8,
      rawBody: 'iudhfviudfnikujbnkj',
      raw: null,
      messageProperties: 'juvisdndivjunsdjik',
      alternativeKey: null,
      dispatchState: 'ERROR',
      dispatchRetry: 7467,
      eventType: 'REGISTERED',
      brand: 'VIVUS',
      crmClientId: '200720857679689',
      metadata: null,
    },
    {
      id: 9,
      rawBody: 'iudhfviudfnikujbnkj',
      raw: null,
      messageProperties: 'juvisdndivjunsdjik',
      alternativeKey: null,
      dispatchState: 'ERROR',
      dispatchRetry: 7467,
      eventType: 'REGISTERED',
      brand: 'VIVUS',
      crmClientId: '200720857679689',
      metadata: null,
    },
    {
      id: 10,
      rawBody: 'iudhfviudfnikujbnkj',
      raw: null,
      messageProperties: 'juvisdndivjunsdjik',
      alternativeKey: null,
      dispatchState: 'ERROR',
      dispatchRetry: 7467,
      eventType: 'REGISTERED',
      brand: 'VIVUS',
      crmClientId: '200720857679689',
      metadata: null,
    },
    {
      id: 11,
      rawBody: 'iudhfviudfnikujbnkj',
      raw: null,
      messageProperties: 'juvisdndivjunsdjik',
      alternativeKey: null,
      dispatchState: 'ERROR',
      dispatchRetry: 7467,
      eventType: 'REGISTERED',
      brand: 'VIVUS',
      crmClientId: '200720857679689',
      metadata: null,
    },
    {
      id: 12,
      rawBody: 'iudhfviudfnikujbnkj',
      raw: null,
      messageProperties: 'juvisdndivjunsdjik',
      alternativeKey: null,
      dispatchState: 'ERROR',
      dispatchRetry: 7467,
      eventType: 'REGISTERED',
      brand: 'VIVUS',
      crmClientId: '200720857679689',
      metadata: null,
    },
    {
      id: 13,
      rawBody: 'iudhfviudfnikujbnkj',
      raw: null,
      messageProperties: 'juvisdndivjunsdjik',
      alternativeKey: null,
      dispatchState: 'ERROR',
      dispatchRetry: 7467,
      eventType: 'REGISTERED',
      brand: 'VIVUS',
      crmClientId: '200720857679689',
      metadata: null,
    },
    {
      id: 14,
      rawBody: 'iudhfviudfnikujbnkj',
      raw: null,
      messageProperties: 'juvisdndivjunsdjik',
      alternativeKey: null,
      dispatchState: 'ERROR',
      dispatchRetry: 7467,
      eventType: 'REGISTERED',
      brand: 'VIVUS',
      crmClientId: '200720857679689',
      metadata: null,
    },
    {
      id: 15,
      rawBody: 'iudhfviudfnikujbnkj',
      raw: null,
      messageProperties: 'juvisdndivjunsdjik',
      alternativeKey: null,
      dispatchState: 'ERROR',
      dispatchRetry: 7467,
      eventType: 'REGISTERED',
      brand: 'VIVUS',
      crmClientId: '200720857679689',
      metadata: null,
    },
    {
      id: 16,
      rawBody: 'iudhfviudfnikujbnkj',
      raw: null,
      messageProperties: 'juvisdndivjunsdjik',
      alternativeKey: null,
      dispatchState: 'ERROR',
      dispatchRetry: 7467,
      eventType: 'REGISTERED',
      brand: 'VIVUS',
      crmClientId: '200720857679689',
      metadata: null,
    },
    {
      id: 17,
      rawBody: 'iudhfviudfnikujbnkj',
      raw: null,
      messageProperties: 'juvisdndivjunsdjik',
      alternativeKey: null,
      dispatchState: 'ERROR',
      dispatchRetry: 7467,
      eventType: 'REGISTERED',
      brand: 'VIVUS',
      crmClientId: '200720857679689',
      metadata: null,
    },
    {
      id: 18,
      rawBody: 'iudhfviudfnikujbnkj',
      raw: null,
      messageProperties: 'juvisdndivjunsdjik',
      alternativeKey: null,
      dispatchState: 'ERROR',
      dispatchRetry: 7467,
      eventType: 'REGISTERED',
      brand: 'VIVUS',
      crmClientId: '200720857679689',
      metadata: null,
    },
    {
      id: 19,
      rawBody: 'iudhfviudfnikujbnkj',
      raw: null,
      messageProperties: 'juvisdndivjunsdjik',
      alternativeKey: null,
      dispatchState: 'ERROR',
      dispatchRetry: 7467,
      eventType: 'REGISTERED',
      brand: 'VIVUS',
      crmClientId: '200720857679689',
      metadata: null,
    },
    {
      id: 20,
      rawBody: 'iudhfviudfnikujbnkj',
      raw: null,
      messageProperties: 'juvisdndivjunsdjik',
      alternativeKey: null,
      dispatchState: 'ERROR',
      dispatchRetry: 7467,
      eventType: 'REGISTERED',
      brand: 'VIVUS',
      crmClientId: '200720857679689',
      metadata: null,
    },
  ],
  pageable: {
    offset: 0,
    pageNumber: 0,
    pageSize: 20,
  },
  totalPages: 500000,
  totalElements: 10000000,
  numberOfElements: 20,
};
