import React from 'react';
import {useDispatch} from 'react-redux';
import {TablePagination} from '@material-ui/core';

import {setPerPageViewer} from 'actions/PerPageViewer/PerPageViewer';

import {DefaultPerPageViewerStates} from 'types/PerPageViewer/DefaultPerPageViewerStates';
import {PerPageViewer} from 'types/PerPageViewer/PerPageViewer';

interface PerPageSelectorProps {
  perPageViewer: PerPageViewer;

  actionDispatcher(): any;
}

/**
 * Используется в сагах при получении таблиц для сброса пагинатора (чтобы не писать объект для каждой таблицы)
 */
export interface ResetPaginator {
  resetPaginator: boolean
}

function getPerPageEnumValues(): number[] {
  return Object.keys(DefaultPerPageViewerStates)
    .filter(v => !isNaN(Number(v)))
    .map(v => Number(v));
}

function PerPageSelector({actionDispatcher, perPageViewer}: PerPageSelectorProps) {
  const {numberOfElements, pageable, totalElements, totalPages} = perPageViewer;


  const dispatch = useDispatch();
  const perPageItems: number[] = getPerPageEnumValues();

  const toSave: PerPageViewer = {
    totalPages,
    totalElements,
    numberOfElements,
    pageable: {
      pageSize: pageable.pageSize,
      offset: pageable.offset,
      pageNumber: pageable.pageNumber,
    },
  };

  const handleClick = (pageSize: number) => {
    toSave.pageable.pageSize = pageSize;
    commonDispatcher(toSave);
  };

  const handlePageChange = (_: any, newPage: number) => {
    toSave.pageable.pageNumber = newPage;
    commonDispatcher(toSave);
  };

  const commonDispatcher = (toSave: PerPageViewer) => {
    dispatch(setPerPageViewer(toSave));
    dispatch(actionDispatcher());
  };

  return (
    <TablePagination
      component="div"
      count={totalElements}
      onPageChange={handlePageChange}
      onRowsPerPageChange={e => handleClick(Number(e.target.value))}
      page={pageable.pageNumber || 0}
      rowsPerPage={pageable.pageSize || perPageItems[0]}
      rowsPerPageOptions={perPageItems}
    />
  );
}

export {PerPageSelector};
