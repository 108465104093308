import React, {useState} from 'react';
import MomentUtils from "@date-io/moment";

import moment from 'moment';
import 'moment/locale/ru'
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";

moment.locale("ru")

interface DatePickerProps {
  title: string;
  value: string | null;
  disabled?: boolean;
  size?: string

  onChange(func: any): any
}

/**
 * Кастомный компонент DateTimePicker, использует moment
 * @param title
 * @param value
 * @param onChange
 * @param disabled
 * @param size
 * @constructor
 */
function DateTimePicker({title, value = null, onChange, disabled = false, size = 'medium'}: DatePickerProps) {
  const [clearedDate, handleClearedDateChange] = useState(value?.length > 0 ? value : null);

  const handleDate = (event) => {
    if (event) {
      handleClearedDateChange(event)
      onChange({target: {value: event.toISOString(false)}})
    } else {
      handleClearedDateChange(null)
      onChange({target: {value: ''}})
    }
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale={"ru"}>
      <KeyboardDateTimePicker
        clearable
        size={size}
        variant="dialog"
        format="DD.MM.YYYY HH:mm:ss"
        ampm={false}
        label={title}
        disabled={disabled}
        inputVariant="outlined"
        value={clearedDate}
        onChange={handleDate}
      />
    </MuiPickersUtilsProvider>
  );
}

export {DateTimePicker};
