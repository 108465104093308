import React from "react";
import {Button, CircularProgress, Grid, TextField} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {StoreState} from "types";
import {STATUS} from "literals";
import {useFormik} from "formik";
import {login} from "actions";
import {LoginformValidationSchema} from "components/Login/components/ValidationSchema";
import {LoginForm} from "types/User/User";
import {Alert} from "@material-ui/lab";

function Form() {
  const dispatch = useDispatch();
  const loginState = useSelector<StoreState>(({login}) => login);
  const isActive = loginState.status === STATUS.RUNNING;

  const handleSubmit = (values) => {
    if (!isActive) {
      dispatch(login(values));
    }
  }

  /**
   * Для валидации используется formik
   */
  const formik = useFormik<LoginForm>({
    initialValues: {
      login: '',
      password: '',
    },
    validationSchema: LoginformValidationSchema,
    onSubmit: handleSubmit,
  });


  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="login"
            label="Логин"
            variant="outlined"
            name="login"
            value={formik.values.login}
            onChange={formik.handleChange}
            error={formik.touched.login && Boolean(formik.errors.login)}
            helperText={formik.touched.login && formik.errors.login}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="password"
            label="Пароль"
            type="password"
            name="password"
            variant="outlined"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
        </Grid>
        <Grid item style={{width: '100%'}} xs={12}>
          <Button
            color="primary"
            disabled={isActive}
            fullWidth
            type="submit"
            size="large"
            variant="contained"
          >
            {isActive ? <CircularProgress size={32}/> : 'Войти'}
          </Button>
        </Grid>
        {
          loginState.error != null ? (
            <Grid item xs={12}>
              <Alert severity="error">{loginState.error}</Alert>
            </Grid>
          ) : <></>
        }
      </Grid>
    </form>
  );
}

export default Form;
