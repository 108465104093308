import {createAction} from 'modules/helpers';

import {ActionTypes} from 'literals';
import {CommissionRule} from "types/CommissionRule/CommissionRule";
import {ResetPaginator} from "components/PerPageSelector/PerPageSelector";

export * from '../Filters/filters';

export const getCommissionRule = createAction(ActionTypes.GET_COMMISSION_RULE, (resetPaginator: ResetPaginator | void) => resetPaginator);
export const saveCommissionRule = createAction(ActionTypes.SAVE_COMMISSION_RULE, (commissionRule: CommissionRule) => commissionRule);
