export interface ThemeShadow {
  larger?: string;
  regular?: string;
  small?: string;
}

export interface ThemeBorder {
  color?: string;
  radius?: string;
  type?: string;
}

export interface Theme {
  border?: ThemeBorder;
  color: string;
  padding: string;
  shadow?: ThemeShadow;
}

const theme: Theme = {
  color: '#11add1',
  padding: '20px',
  border: {
    radius: '6px',
  },
  shadow: {
    regular: '0 .5rem 1rem rgba(0,0,0,.15)',
  },
};

export { theme };
