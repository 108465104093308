import { Button, Paper } from '@material-ui/core';
import styled from 'styled-components';

const ModalWrapper = styled(Paper)`
  position: absolute;
  top: 30%;
  left: 50%;
  padding: 50px;
  transform: translate(-50%, -30%);
`;

const ModalButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CancelButton = styled(Button)`
  margin-left: 10px;
`;

export { ModalWrapper, ModalButtonsWrapper, CancelButton };
