export interface APIConfig {
  url: string;
}

export const apiConfig: APIConfig = {
  url: process.env.NODE_ENV === "production" ? // change to production
    'https://postback.smsfinance.ru/postback/v1' :
    'http://sms-app-stage-01.stage.c.fin/postback/v1',
};

export interface CommonTable {
  pageNo: number;
  pageSize: number;
}

export interface APILeadsTableRequestParams extends CommonTable {
  brand: string;
  eventType: string;
  leadGeneratorId: number | null | undefined | string;
  template: string;
  tariff: string | number;
}

export interface APILeadGeneratorRequestParams extends CommonTable {
  id: string;
  name: string;
}

export interface APITablePostbackRequestParams extends CommonTable {
  brand: string;
  // commission: number | string;
  crmClientId: string;
  // crmClientLimit: number | string;
  // dispatchRetry: number | string;
  dispatchState: string;
  startEventOccurred: string;
  finishEventOccurred: string;
  eventType: string;
  // statusMessage: string;
}

export interface APITableCommissionRuleRequestParams extends CommonTable {
  brand: string;
  commission: number | string;
  endIssueDate?: string;
  maxAmount: number | string;
  minAmount: number | string;
  startIssueDate?: string;
  leadGeneratorId?: string | number | null | undefined;
}
