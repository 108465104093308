import {createReducer} from 'modules/helpers';

import {ActionTypes, STATUS} from 'literals';

import {LeadGeneratorTemplateState} from 'types/LeadGeneratorTemplate/LeadGeneratorTemplate';

export const leadGeneratorTemplateState: LeadGeneratorTemplateState = {
  status: STATUS.RUNNING,
  leadGeneratorTemplate: [
    {
      id: 72,
      brand: 'SMSFINANCE',
      eventType: 'REGISTERED',
      leadGenerator: {
        id: '',
        name: ''
      },
      template: 'https://unicom24.ru/offer/postback/{click_id}/?status=receive',
      created: '2019-12-16T11:52:03.977+00:00',
      enabled: true,
      tariff: ''
    },
  ],
  perPageViewer: {
    totalPages: 1,
    totalElements: 1,
    numberOfElements: 1,
    pageable: {
      offset: 1,
      pageSize: 25,
      pageNumber: 1,
    },
  },
};

export default {
  leadGeneratorTemplate: createReducer<LeadGeneratorTemplateState>(
    {
      [ActionTypes.GET_LEAD_GENERATOR_TEMPLATE]: draft => {
        draft.status = STATUS.RUNNING;
      },
      [ActionTypes.GET_LEAD_GENERATOR_TEMPLATE_SUCCESS]: (draft, {payload}) => {
        draft.leadGeneratorTemplate = payload.leadGeneratorTemplate;
        draft.perPageViewer = payload.perPageViewer;
        draft.status = payload.status;
      },
    },
    leadGeneratorTemplateState,
  ),
};
