import {now} from '@gilbarbara/helpers';
import {all, put, select, takeLatest} from 'redux-saga/effects';
import {call} from 'redux-saga-test-plan/matchers';

import {APITablePostbackRequestParams} from 'config/Api';
import {ActionTypes, STATUS} from 'literals';

import {StoreAction, StoreState} from 'types';
import {FiltersState} from 'types/Filters/FiltersState';
import {Pageable, PerPageViewer} from 'types/PerPageViewer/PerPageViewer';
import {Postback, PostbackState} from 'types/Postback/Postback';
import {API} from "config/Api.routes";
import {apiRequest} from "sagas/API/API";
import {cloneDeep} from "lodash";

export interface PostbackFromAPI {
  content: Postback[];
  numberOfElements: number;
  pageable: Pageable;
  totalElements: number;
  totalPages: number;
}

function getFilterParams(filters: FiltersState, perPageViewer: PerPageViewer): APITablePostbackRequestParams {
  return {
    brand: filters.postback.brand,
    // commission: filters.postback.commission,
    crmClientId: filters.postback.crmClientId,
    // crmClientLimit: filters.postback.crmClientLimit,
    // dispatchRetry: filters.postback.dispatchRetry,
    dispatchState: filters.postback.dispatchState,
    startEventOccurred: filters.postback.startEventOccurred,
    finishEventOccurred: filters.postback.finishEventOccurred,
    eventType: filters.postback.eventType,
    // statusMessage: filters.postback.statusMessage,

    pageNo: perPageViewer.pageable.pageNumber,
    pageSize: perPageViewer.pageable.pageSize,
  }
}

export function* getPostback(action: StoreAction) {
  try {
    const filters: FiltersState = yield select((s: StoreState) => s.filters);
    const perPageViewer: PerPageViewer = cloneDeep(yield select((s: StoreState) => s.perPageViewer));

    if (action?.payload?.resetPaginator) {
      perPageViewer.pageable.pageNumber = 0
    }

    const fromApi: PostbackFromAPI = yield call(
      apiRequest,
      API.POSTBACK,
      "GET",
      null,
      getFilterParams(filters, perPageViewer),
    );

    const postbackFromApi: PostbackState = {
      postback: fromApi.content,
      perPageViewer: {
        pageable: fromApi.pageable,
        totalPages: fromApi.totalPages,
        totalElements: fromApi.totalElements,
        numberOfElements: fromApi.numberOfElements,
      },
      status: STATUS.SUCCESS,
    };

    yield all([
      yield put({
        type: ActionTypes.GET_POSTBACK_SUCCESS,
        payload: postbackFromApi,
        meta: {cached: true, updatedAt: now()},
      }),
      yield put({
        type: ActionTypes.SET_PER_PAGE_VIEWER,
        payload: postbackFromApi.perPageViewer,
        meta: {cached: true, updatedAt: now()},
      }),
    ]);
  } catch (error) {
    console.log(error);
  }
}

export default function* root() {
  yield all([takeLatest(ActionTypes.GET_POSTBACK, getPostback)]);
}
