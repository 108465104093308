import React from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useMount} from 'react-use';
import {
  CircularProgress,
  Fab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {useShallowEqualSelector} from 'modules/hooks';
import {STATUS} from 'literals';
import {getDictionaries, setFilters} from 'actions';
import {InputFilter} from 'components/Filters/Input/InputFilter';
import {PerPageSelector} from 'components/PerPageSelector/PerPageSelector';
import {TableControls, TableLoadingWrapper, TableWrapper,} from 'components/Common/Table/Styled/TableWrapper';
import {FiltersState} from 'types/Filters/FiltersState';
import {PerPageViewer} from 'types/PerPageViewer/PerPageViewer';
import {CommissionRule, CommissionRuleState} from "types/CommissionRule/CommissionRule";
import {getCommissionRule} from "actions/CommissionRule";
import {FilteredTableDatePickerControl} from "components/Common/Table/Styled/FilterWrapper";
import {Add} from "@material-ui/icons";
import {DateTimeFilter} from "components/Filters/DateTime/DateTimeFilter";
import {getFormatedDate} from "utils/helpers";
import {DictionarySelector} from "components/Filters/Selectors/DictionarySelector";
import {Dictionaries} from "types/Dictionaries/Dictionaries";

function CommissionRuleTable() {
  const history = useHistory();

  const dictionaries = useShallowEqualSelector<Dictionaries>(({dictionaries}) => dictionaries);
  const filters = useShallowEqualSelector<FiltersState>(({filters}) => filters);
  const perPageViewer = useShallowEqualSelector<PerPageViewer>(
    ({perPageViewer}) => perPageViewer,
  );

  const commissionRuleState: CommissionRuleState = useShallowEqualSelector<CommissionRuleState>(({commissionRule}) => commissionRule);

  const selectCommissionRule = (commissionRule: CommissionRule | null = null) => {
    history.push('/postback_commission_rule/update', commissionRule);
  };

  const dispatch = useDispatch();

  useMount(() => {
    dispatch(getDictionaries());
    dispatch(getCommissionRule({resetPaginator: true}));
  });

  const useFilter = (stack: string[], value: string | boolean) => {
    const save = {
      [stack[0]]: {
        [stack[1]]: value,
      },
    };

    dispatch(setFilters({...filters, ...save}));
    dispatch(getCommissionRule());
  };

  return (
    <div>
      <TableWrapper>
        {commissionRuleState.status != STATUS.SUCCESS || (
          <TableControls>
            <div>
              <Fab
                color="primary"
                onClick={() => selectCommissionRule()}
                size="small"
                style={{marginRight: '10px'}}
              >
                <Add/>
              </Fab>
              {/*<Fab aria-label="add" color="primary" size="small">*/}
              {/*  <CloudDownload/>*/}
              {/*</Fab>*/}
            </div>
            <PerPageSelector actionDispatcher={getCommissionRule} perPageViewer={perPageViewer}/>
          </TableControls>
        )}
        <TableContainer>
          {commissionRuleState.status === STATUS.SUCCESS ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell component="th">
                    <DictionarySelector
                      current={filters.commissionRule.brand}
                      dictionaries={dictionaries.brands}
                      dispatcher={useFilter}
                      stack={['commissionRule', 'brand']}
                      title="brand"
                    />
                  </TableCell>
                  <TableCell component="th">
                    <InputFilter
                      current={filters.commissionRule.commission}
                      dispatcher={useFilter}
                      stack={['commissionRule', 'commission']}
                      title="commission"
                    />
                  </TableCell>
                  <TableCell>
                    <DictionarySelector
                      current={filters.commissionRule.leadGenerator}
                      dictionaries={dictionaries.leadGenerator}
                      dispatcher={useFilter}
                      setNameFromObject={"name"}
                      stack={['commissionRule', 'leadGenerator']}
                      title="Lead generator"
                    />
                  </TableCell>
                  <TableCell component="th">
                    <InputFilter
                      current={filters.commissionRule.utmContent}
                      dispatcher={useFilter}
                      stack={['commissionRule', 'utmContent']}
                      title="utmContent"
                    />
                  </TableCell>
                  <TableCell component="th">
                    <FilteredTableDatePickerControl>
                      <DateTimeFilter
                        size="medium"
                        current={filters.commissionRule.startIssueDate}
                        dispatcher={useFilter}
                        stack={['commissionRule', 'startIssueDate']}
                        title="startIssueDate"/>
                    </FilteredTableDatePickerControl>
                  </TableCell>
                  <TableCell component="th">
                    <FilteredTableDatePickerControl>
                      <DateTimeFilter
                        size="medium"
                        current={filters.commissionRule.endIssueDate}
                        dispatcher={useFilter}
                        stack={['commissionRule', 'endIssueDate']}
                        title="endIssueDate"
                      />
                    </FilteredTableDatePickerControl>
                  </TableCell>
                  <TableCell component="th">
                    <InputFilter
                      current={filters.commissionRule.minAmount}
                      dispatcher={useFilter}
                      stack={['commissionRule', 'minAmount']}
                      title="minAmount"
                    />
                  </TableCell>
                  <TableCell component="th">
                    <InputFilter
                      current={filters.commissionRule.maxAmount}
                      dispatcher={useFilter}
                      stack={['commissionRule', 'maxAmount']}
                      title="maxAmount"
                    />
                  </TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {commissionRuleState.commissionRule.map(commissionRule => {
                  return (
                    <TableRow
                      key={commissionRule.id}
                      hover
                      onClick={() => selectCommissionRule(commissionRule)}
                      style={{
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                      }}
                    >
                      <TableCell>
                        <div>{commissionRule.brand}</div>
                      </TableCell>
                      <TableCell>
                        <div>{commissionRule.commission}</div>
                      </TableCell>
                      <TableCell>
                        <div>{commissionRule.leadGenerator.name}</div>
                      </TableCell>
                      <TableCell>
                        <div>{commissionRule.utmContent}</div>
                      </TableCell>
                      <TableCell>
                        <div>{getFormatedDate(commissionRule.startIssueDate)}</div>
                      </TableCell>
                      <TableCell>
                        <div>{getFormatedDate(commissionRule.endIssueDate)}</div>
                      </TableCell>
                      <TableCell>
                        <div>{commissionRule.minAmount}</div>
                      </TableCell>
                      <TableCell>
                        <div>{commissionRule.maxAmount}</div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          ) : (
            <TableLoadingWrapper>
              <CircularProgress/>
            </TableLoadingWrapper>
          )}
        </TableContainer>
      </TableWrapper>
    </div>
  );
}

export default CommissionRuleTable;
