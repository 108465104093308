import { Paper } from '@material-ui/core';
import styled from 'styled-components';

const TableWrapper = styled(Paper)`
  margin-top: 100px;
`;

const TableControls = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
`;

const TableLoadingWrapper = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export { TableControls, TableLoadingWrapper, TableWrapper };
