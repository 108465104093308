/**
 * Configuration
 * @module config
 */

const config = {
  name: 'Postback service',
  description: '',
};

export default config;
