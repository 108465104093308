import React from 'react';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  
  html {
    -webkit-font-smoothing: antialiased;
    height: 100%;
  }

  body {
    font-family: Lato, sans-serif;
    font-size: 16px; /* stylelint-disable unit-disallowed-list */
    margin: 0;
    min-height: 100vh;
    padding: 0;
  }

  // По меньше шрифт в фильтрах
  .form-floating, .form-floating > .form-select, .form-floating > .form-control{
    font-size: 14px;
  }

  .MuiButton-containedSizeLarge {
    padding: 15px 22px !important;
  }

  .MuiContainer-maxWidthXl {
    max-width: 1840px;
  }
  
`;

export default function GlobalStyles() {
  return <GlobalStyle />;
}
