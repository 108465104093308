import React from 'react';
import { Container } from '@material-ui/core';

type Props = {
  children?: any;
  onError?: (error: Error, componentStack: string) => void;
};

type ErrorInfo = {
  componentStack: string;
};

type State = {
  error: Error | null;
};

export default class ErrorHandler extends React.Component<Props, State> {
  public state: State = {
    error: null,
  };

  componentDidCatch(error: Error, info: ErrorInfo) {
    const { onError } = this.props;

    /* istanbul ignore else */
    if (typeof onError === 'function') {
      try {
        onError.call(this, error, info?.componentStack);
      } catch {
        // ignore
      }
    }

    this.setState({ error });
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error === null) {
      return children;
    }

    const message = error.toString();

    return (
      <Container>
        <h1>{message}</h1>
      </Container>
    );
  }
}
