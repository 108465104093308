import styled from 'styled-components';

const FlexCenter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const PrivateWrapper = styled.div`
  margin-top: 64px;
`;

const Title = styled.h1`
  padding: 0 10px;
  font-size: 2rem;
  display: flex;
  justify-content: flex-end;
`;

export { FlexCenter, PrivateWrapper, Title };
