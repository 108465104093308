import React from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useMount} from 'react-use';
import {
  CircularProgress,
  Fab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import {useShallowEqualSelector} from 'modules/hooks';

import {STATUS} from 'literals';

import {setFilters} from 'actions';
import {InputFilter} from 'components/Filters/Input/InputFilter';
import {PerPageSelector} from 'components/PerPageSelector/PerPageSelector';
import {TableControls, TableLoadingWrapper, TableWrapper,} from 'components/Common/Table/Styled/TableWrapper';
import {FiltersState} from 'types/Filters/FiltersState';
import {PerPageViewer} from 'types/PerPageViewer/PerPageViewer';
import {Add, CloudDownload} from "@material-ui/icons";
import {LeadGenerator, LeadGeneratorState} from "types/LeadGenerator/LeadGenerator";
import {getLeadGenerator} from "actions/LeadGenerator";

function LeadGeneratorTable() {
  const history = useHistory();

  const filters = useShallowEqualSelector<FiltersState>(({filters}) => filters);
  const perPageViewer = useShallowEqualSelector<PerPageViewer>(
    ({perPageViewer}) => perPageViewer,
  );

  const leadGeneratorState: LeadGeneratorState = useShallowEqualSelector<LeadGeneratorState>(({leadGenerator}) => leadGenerator);

  const selectLead = (lead: LeadGenerator | null = null) => {
    history.push('/lead_generator/update', lead);
  };

  const dispatch = useDispatch();

  useMount(() => {
    dispatch(getLeadGenerator({resetPaginator: true}));
  });

  const useFilter = (stack: string[], value: string | boolean) => {
    const save = {
      [stack[0]]: {
        [stack[1]]: value,
      },
    };

    dispatch(setFilters({...filters, ...save}));
    dispatch(getLeadGenerator());
  };

  return (
    <div>
      <TableWrapper>
        {leadGeneratorState.status != STATUS.SUCCESS || (
          <TableControls>
            <div>
              <Fab
                aria-label="add"
                color="primary"
                onClick={() => selectLead()}
                size="small"
                style={{marginRight: '10px'}}
              >
                <Add/>
              </Fab>
              <Fab aria-label="add" color="primary" size="small">
                <CloudDownload/>
              </Fab>
            </div>
            <PerPageSelector actionDispatcher={getLeadGenerator} perPageViewer={perPageViewer}/>
          </TableControls>
        )}
        <TableContainer>
          {leadGeneratorState.status === STATUS.SUCCESS ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <InputFilter
                      current={filters.leadGenerator.id}
                      dispatcher={useFilter}
                      type="number"
                      stack={['leadGenerator', 'id']}
                      title="ID"
                      disabled
                    />
                  </TableCell>
                  <TableCell>
                    <InputFilter
                      current={filters.leadGenerator.name}
                      dispatcher={useFilter}
                      stack={['leadGenerator', 'name']}
                      title="Name"
                      disabled
                    />
                  </TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {leadGeneratorState.leadGenerator.map(lead => {
                  return (
                    <TableRow
                      key={lead.id}
                      hover
                      onClick={() => selectLead(lead)}
                      style={{
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                      }}
                    >
                      <TableCell>
                        <div>{lead.id}</div>
                      </TableCell>
                      <TableCell>
                        <div>{lead.name}</div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          ) : (
            <TableLoadingWrapper>
              <CircularProgress/>
            </TableLoadingWrapper>
          )}
        </TableContainer>
      </TableWrapper>
    </div>
  );
}

export default LeadGeneratorTable;
