import React, {useEffect, useState} from 'react';
import {
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';

import {FullWidthFormControl} from 'components/Common/Styled/FormWrapper';
import {CancelButton, ModalButtonsWrapper, ModalWrapper,} from 'components/Common/Styled/ModalWrapper';
import {LoginCardTitle} from 'components/Styled/LoginWrappers';

import {Dictionaries} from 'types/Dictionaries/Dictionaries';
import {LeadGeneratorTemplate} from 'types/LeadGeneratorTemplate/LeadGeneratorTemplate';
import {Cancel, Save} from "@material-ui/icons";
import {findIndex} from "lodash";

interface FormUpdateProp {
  dictionaries: Dictionaries;
  leadGeneratorTemplate: LeadGeneratorTemplate;

  handleSubmit(lead: LeadGeneratorTemplate): void;
}

const mock: LeadGeneratorTemplate = {
  brand: '',
  created: '',
  enabled: false,
  eventType: '',
  id: 0,
  leadGenerator: {
    id: 0,
    name: ''
  },
  template: '',
  tariff: 0
};

function Form({dictionaries, handleSubmit, leadGeneratorTemplate}: FormUpdateProp) {

  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [leadCopy, setLeadCopy] = useState<LeadGeneratorTemplate>(leadGeneratorTemplate || mock);

  useEffect(() => {
    setLeadCopy(leadGeneratorTemplate || mock);
  }, [leadGeneratorTemplate]);

  const saveLeadGeneratorTemplate = () => {
    handleSubmit(leadCopy);
    setLoading(true);
    // history.push('lidogen')
  };

  const setLead = (lead: any) => {
    setLeadCopy({...leadCopy, ...lead});
  };

  const handleModalState = (value: boolean) => {
    setModal(value);
  };

  return (
    <div>
      <Grid
        alignItems="center"
        container
        direction="column"
        justifyContent="center"
        spacing={3}
        style={{marginTop: '30px'}}
      >
        <Grid item style={{width: '100%'}} xs={12}>
          <FullWidthFormControl variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">Бренд</InputLabel>
            <Select
              label="Бренд"
              onChange={e => setLead({brand: e.target.value as string})}
              value={leadCopy.brand || ''}
            >
              <MenuItem disabled value="">
                None
              </MenuItem>
              {dictionaries.brands.map((b, index) => {
                return (
                  <MenuItem key={index} value={b}>
                    {b}
                  </MenuItem>
                );
              })}
            </Select>
          </FullWidthFormControl>
        </Grid>

        <Grid item style={{width: '100%'}} xs={12}>
          <FullWidthFormControl variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">Тип ивента</InputLabel>
            <Select
              label="Тип ивента"
              onChange={e => setLead({eventType: e.target.value as string})}
              value={leadCopy.eventType || ''}
            >
              <MenuItem disabled value="">
                None
              </MenuItem>
              {dictionaries.eventTypes.map((b, index) => {
                return (
                  <MenuItem key={index} value={b}>
                    {b}
                  </MenuItem>
                );
              })}
            </Select>
          </FullWidthFormControl>
        </Grid>

        <Grid item style={{width: '100%'}} xs={12}>
          <FullWidthFormControl variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">Lead generator</InputLabel>
            <Select
              label="Lead generator"
              onChange={e => setLead({leadGenerator: dictionaries.leadGenerator[e.target.value as number]})}
              value={
                findIndex(dictionaries.leadGenerator, leadCopy.leadGenerator) > -1 ?
                findIndex(dictionaries.leadGenerator, leadCopy.leadGenerator) : ''
              }
            >
              <MenuItem value="">
                None
              </MenuItem>
              {dictionaries.leadGenerator.map((b, index) => {
                return (
                  <MenuItem key={index} value={index}>
                    {b.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FullWidthFormControl>
        </Grid>

        <Grid item style={{width: '100%'}} xs={12}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Тариф"
            type="number"
            onChange={e => setLead({tariff: e.target.value as string})}
            value={leadCopy.tariff || ''}
            variant="outlined"
          />
        </Grid>

        <Grid item style={{width: '100%'}} xs={12}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Шаблон"
            onChange={e => setLead({template: e.target.value as string})}
            value={leadCopy.template || ''}
            variant="outlined"
          />
        </Grid>

        <Grid item style={{width: '100%'}} xs={12}>
          <FullWidthFormControl variant="outlined">
            <InputLabel htmlFor="outlined-age-native-simple">Включен</InputLabel>
            <Select
              label="Включен"
              onChange={e => setLead({enabled: (e.target.value as string) === 'Да'})}
              value={leadCopy.enabled ? 'Да' : 'Нет'}
            >
              <MenuItem disabled value="">
                Placeholder
              </MenuItem>
              <MenuItem value="Да">Да</MenuItem>
              <MenuItem value="Нет">Нет</MenuItem>
            </Select>
          </FullWidthFormControl>
        </Grid>

        <Grid item style={{width: '100%'}} xs={12}>
          <Button
            color="primary"
            disabled={modal}
            fullWidth
            onClick={() => handleModalState(true)}
            size="large"
            variant="contained"
          >
            Сохранить
          </Button>
        </Grid>
      </Grid>

      <Modal
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        onClose={() => handleModalState(false)}
        open={modal}
      >
        <ModalWrapper>
          <LoginCardTitle>
            <Typography component="h2" variant="h5">
              Сохранить лидогенератор ?!
            </Typography>
          </LoginCardTitle>
          <ModalButtonsWrapper>
            <Button
              color="primary"
              disabled={loading}
              fullWidth
              onClick={saveLeadGeneratorTemplate}
              size="large"
              startIcon={!loading && <Save/>}
              variant="contained"
            >
              {loading ? <CircularProgress size={32}/> : 'Сохранить'}
            </Button>
            <CancelButton
              color="secondary"
              disabled={loading}
              fullWidth
              onClick={() => setModal(false)}
              size="large"
              startIcon={<Cancel/>}
              variant="contained"
            >
              Отмена
            </CancelButton>
          </ModalButtonsWrapper>
        </ModalWrapper>
      </Modal>
    </div>
  );
}

export default Form;
