import React from 'react';
import {useDispatch} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {AppBar, Divider, Drawer, IconButton, List, ListItem, Toolbar,} from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {Description, ExitToApp, FeaturedPlayList, Receipt, Subtitles} from '@material-ui/icons';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';

import {logOut} from 'actions';

import DrawerTitle from 'components/Layout/DrawerTitle';
import Header from 'components/Layout/Header';
import {isActive} from 'routes/Routes';

interface MainDrawerProps {
  classes: any;
}

function MainDrawer({classes}: MainDrawerProps) {
  const [open, setOpen] = React.useState(false);

  const location = useLocation();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  const handleClickLogout = () => {
    dispatch(logOut());
  };

  const history = useHistory();

  const handleClick = (route: string) => {
    history.push(route);
  };

  return (
    <div>
      <AppBar
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        position="fixed"
      >
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
            color="inherit"
            edge="start"
            onClick={handleDrawerOpen}
          >
            <MenuIcon/>
          </IconButton>
          <Header/>
        </Toolbar>
      </AppBar>

      <Drawer
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        variant="permanent"
      >
        <div className={classes.toolbar}>
          <DrawerTitle>Postback</DrawerTitle>
          <IconButton onClick={handleDrawerClose}>
            {/*{theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}*/}
            <ChevronLeftIcon/>
          </IconButton>
        </div>
        <List>
          <ListItem
            button
            onClick={() => handleClick('/lead_generator')}
            selected={isActive('/lead_generator', location.pathname)}
          >
            <ListItemIcon>
              <FeaturedPlayList/>
            </ListItemIcon>
            <ListItemText primary="Lead generator"/>
          </ListItem>
        </List>
        <Divider/>
        <List>
          <ListItem
            button
            onClick={() => handleClick('/lead_generator_template')}
            selected={isActive('/lead_generator_template', location.pathname)}
          >
            <ListItemIcon>
              <Description/>
            </ListItemIcon>
            <ListItemText primary="Lead generator template"/>
          </ListItem>
          <ListItem
            button
            onClick={() => handleClick('/postback_event_message')}
            selected={isActive('/postback_event_message', location.pathname)}
          >
            <ListItemIcon>
              <Receipt/>
            </ListItemIcon>
            <ListItemText primary="Postback event message"/>
          </ListItem>
          <ListItem
            button
            onClick={() => handleClick('/postback_commission_rule')}
            selected={isActive('/postback_commission_rule', location.pathname)}
          >
            <ListItemIcon>
              <Subtitles/>
            </ListItemIcon>
            <ListItemText primary="Postback commission rules"/>
          </ListItem>
        </List>
        <Divider/>
        <List>
          <ListItem button onClick={handleClickLogout}>
            <ListItemIcon>
              <ExitToApp/>
            </ListItemIcon>
            <ListItemText primary="Выход"/>
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
}

export {MainDrawer};
