import {all, fork} from 'redux-saga/effects';

import dictionaries from './Dictionaries/index';
import leadGeneratorTemplate from './LeadGeneratorTemplate/LeadGeneratorTemplate';
import leadGenerator from './LeadGenerator/LeadGenerator';
import postback from './Postback/Postback';
import commissionRule from './CommissionRule/CommissionRule';
import login from './User/Login';
import apiRequest from './API/API';

/**
 * rootSaga
 */
export default function* root() {
  yield all([
    fork(login),
    fork(dictionaries),
    fork(leadGeneratorTemplate),
    fork(postback),
    fork(commissionRule),
    fork(apiRequest),
    fork(leadGenerator),
  ]);
}
