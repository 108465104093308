import React from 'react';
import {useDispatch} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {Container, Typography} from '@material-ui/core';

import Form from 'components/LeadGenerator/Form/Form';
import {FormWrapper} from 'components/Common/Styled/FormWrapper';
import {LeadGenerator} from "types/LeadGenerator/LeadGenerator";
import {saveLeadGenerator} from "actions/LeadGenerator";

function Update() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<LeadGenerator>();

  const handleSubmit = (lead: LeadGenerator) => {
    dispatch(saveLeadGenerator(lead));
    history.goBack();
  };

  const getTitle = () => {
    return location?.state
      ? `Изменение lead generator #${location?.state?.id}`
      : 'Создание lead generator';
  };

  return (
    <div>
      <Container maxWidth="sm">
        <FormWrapper>
          <Typography variant="h6">{getTitle()}</Typography>
          <Form handleSubmit={handleSubmit} leadGenerator={location.state}/>
        </FormWrapper>
      </Container>
    </div>
  );
}

export default Update;
