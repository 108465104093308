import {now} from '@gilbarbara/helpers';
import {all, call, put, takeLatest} from 'redux-saga/effects';

import {ActionTypes, STATUS} from 'literals';

import {brandDictionaryMock, enabledDictionaryMock, eventTypeDictionaryMock,} from 'sagas/Mocks/Mocs';
import {Dictionaries} from 'types/Dictionaries/Dictionaries';
import {API} from "config/Api.routes";
import {LeadGeneratorFromAPI} from "sagas/LeadGenerator/LeadGenerator";
import {apiRequest} from "sagas/API/API";

export function* getDictionaries() {
  try {

    const brands: string[] = brandDictionaryMock;
    const eventTypes: string[] = eventTypeDictionaryMock;
    const enabled: string[] = enabledDictionaryMock;

    /**
     * Здесь используется API запрос, так как нам нужны все значения
     * если юзать сагу, то надо туда костылить значения пагинации в параметры
     */
    const leadGenerator: LeadGeneratorFromAPI = yield call(
      apiRequest,
      API.LEAD_GENERATOR,
      "GET",
      null,
      {
        pageNo: 0,
        pageSize: 1000
      },
    );

    if (leadGenerator) {
      const dictionariesFromApi: Dictionaries = {
        brands,
        eventTypes,
        enabled,
        leadGenerator: leadGenerator.content,
        status: STATUS.SUCCESS,
      };

      yield put({
        type: ActionTypes.GET_DICTIONARIES_SUCCESS,
        payload: dictionariesFromApi,
        meta: {cached: true, updatedAt: now()},
      });
    }


  } catch (error) {
    yield put({
      type: ActionTypes.GET_DICTIONARIES,
      payload: error,
      meta: {},
    });
  }
}

export default function* root() {
  yield all([takeLatest(ActionTypes.GET_DICTIONARIES, getDictionaries)]);
}
