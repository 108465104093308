import React from 'react';
import {Container, Typography,} from '@material-ui/core';

import {FlexCenter} from 'components/Styled/Common';
import {LoginCard, LoginCardTitle, LoginWrapper} from 'components/Styled/LoginWrappers';
import Form from "components/Login/components/Form";

function Login() {
  return (
    <LoginWrapper>
      <Container>
        <FlexCenter>
          <LoginCard>
            <LoginCardTitle>
              <Typography component="h2" variant="h5">
                Postback service
              </Typography>
            </LoginCardTitle>
            <Form/>
          </LoginCard>
        </FlexCenter>
      </Container>
    </LoginWrapper>
  );
}

export default Login;
