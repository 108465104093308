/**
 * @module Actions/Login
 * @desc Login Actions
 */
import {createAction} from 'modules/helpers';

import {ActionTypes} from 'literals';
import {LoginForm} from "types/User/User";

export const login = createAction(ActionTypes.USER_LOGIN_REQUEST, (loginForm: LoginForm) => (loginForm));
export const logOut = createAction(ActionTypes.USER_LOGOUT_REQUEST, () => ({}));
