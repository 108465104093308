import { createReducer } from 'modules/helpers';

import { ActionTypes } from 'literals';

import { PerPageViewer } from 'types/PerPageViewer/PerPageViewer';

/**
 * Деволтный стейт количества постраничных записей в таблице
 */
export const perPageViewerState: PerPageViewer = {
  numberOfElements: 25,
  pageable: {
    offset: 1,
    pageSize: 25,
    pageNumber: 0,
  },
  totalElements: 10000000,
  totalPages: 2,
};

export default {
  perPageViewer: createReducer<PerPageViewer>(
    {
      [ActionTypes.SET_PER_PAGE_VIEWER]: (draft, { payload }) => {
        draft.totalPages = payload.totalPages;
        draft.totalElements = payload.totalElements;
        draft.numberOfElements = payload.numberOfElements;
        draft.pageable = payload.pageable;
      },
    },
    perPageViewerState,
  ),
};
