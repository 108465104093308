import React from 'react';

import { FilteredTableInputControl } from 'components/Common/Table/Styled/FilterWrapper';

interface InputFilterProps {
  current: any;
  dispatcher(stack: string[], value: string | boolean): void;
  stack: string[];
  disabled?: boolean;
  title: string;
  type?: string;
}

/**
 * Поле фильтра
 * @param current
 * @param title
 * @param dispatcher
 * @param stack
 * @param disabled
 * @param type
 * @constructor
 */
function InputFilter({ current, dispatcher, stack, title, disabled = false, type = "text" }: InputFilterProps) {
  const setFilter = (event: any) => {
    if (event.key === 'Enter') {
      dispatcher(stack, event.target.value as string);
    }
  };

  return (
    <FilteredTableInputControl
      defaultValue={current}
      id="outlined-basic"
      label={title}
      type={type}
      onKeyDown={e => setFilter(e)}
      variant="outlined"
      disabled={disabled}
    />
  );
}

export { InputFilter };
