import React from 'react';

import { FilteredTableInputControl } from 'components/Common/Table/Styled/FilterWrapper';
import {DatePicker} from "components/Layout/Input/DatePicker/DatePicker";
import {FullWidthFormControl} from "components/Common/Styled/FormWrapper";
import moment from "moment";
import {DoubleInputFilterSty} from "components/Filters/DateTime/Styled/DoublieInputFilterSty";

interface InputFilterProps {
  current: any;
  dispatcher(stack: string[], value: string | boolean): void;
  stack: string[];
  disabled?: boolean;
  title: string;
  size?: string;
}

/**
 * Поле фильтра
 * @param current
 * @param title
 * @param dispatcher
 * @param stack
 * @param disabled
 * @param size
 * @constructor
 */
function DateTimeFilter({ current, dispatcher, stack, title, disabled = false, size = 'medium' }: InputFilterProps) {
  const setFilter = (date: string) => {
      dispatcher(stack, date);
  };

  return (
        <DatePicker
          fullWidth
          size={size}
          id="outlined-basic"
          title={title}
          onChange={event => setFilter(event.target.value)}
          value={current}
          variant="outlined"
          disabled={disabled}
        />
  );
}

export { DateTimeFilter };
