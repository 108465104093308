import {now} from '@gilbarbara/helpers';
import {all, put, select, takeLatest, call} from 'redux-saga/effects';

import {APITableCommissionRuleRequestParams} from 'config/Api';
import {ActionTypes, STATUS} from 'literals';

import {StoreAction, StoreState} from 'types';
import {FiltersState} from 'types/Filters/FiltersState';
import {Pageable, PerPageViewer} from 'types/PerPageViewer/PerPageViewer';
import {API} from "config/Api.routes";
import {apiRequest} from "sagas/API/API";
import {CommissionRule, CommissionRuleState, CommissionRuleToSave} from "types/CommissionRule/CommissionRule";
import {cloneDeep} from "lodash";

export interface CommissionRuleFromAPI {
  content: CommissionRule[];
  numberOfElements: number;
  pageable: Pageable;
  totalElements: number;
  totalPages: number;
}

/**
 * Подготовка параметров фильтра для таблицы
 * @param filters
 * @param perPageViewer
 */
function getFilterParams(filters: FiltersState, perPageViewer: PerPageViewer): APITableCommissionRuleRequestParams {
  return {
    brand: filters.commissionRule.brand,
    commission: filters.commissionRule.commission,
    endIssueDate: filters.commissionRule.endIssueDate,
    pageNo: perPageViewer.pageable.pageNumber,
    pageSize: perPageViewer.pageable.pageSize,
    maxAmount: filters.commissionRule.maxAmount,
    minAmount: filters.commissionRule.minAmount,
    startIssueDate: filters.commissionRule.startIssueDate,
    leadGeneratorId: filters.commissionRule.leadGenerator?.id,
  }
}

/**
 * получение списка commissionRule
 */
export function* getCommissionRule(action: StoreAction | undefined) {
  try {
    const filters: FiltersState = yield select((s: StoreState) => s.filters);
    const perPageViewer: PerPageViewer = cloneDeep(yield select((s: StoreState) => s.perPageViewer));

    if (action?.payload?.resetPaginator) {
      perPageViewer.pageable.pageNumber = 0
    }

    const fromApi: CommissionRuleFromAPI = yield call(
      apiRequest,
      API.COMMISSION_RULE,
      "GET",
      null,
      getFilterParams(filters, perPageViewer),
    );

    if (fromApi) {
      const commissionRulesFromApi: CommissionRuleState = {
        commissionRule: fromApi.content,
        perPageViewer: {
          pageable: fromApi.pageable,
          totalPages: fromApi.totalPages,
          totalElements: fromApi.totalElements,
          numberOfElements: fromApi.numberOfElements,
        },
        status: STATUS.SUCCESS
      };

      yield all([
        put({
          type: ActionTypes.GET_COMMISSION_RULE_SUCCESS,
          payload: commissionRulesFromApi,
          meta: {cached: true, updatedAt: now()},
        }),
        put({
          type: ActionTypes.SET_PER_PAGE_VIEWER,
          payload: commissionRulesFromApi.perPageViewer,
          meta: {cached: true, updatedAt: now()},
        }),
      ]);
    }

  } catch (error) {
    console.error(error);
  }
}

/**
 * Сохранение детальки commissionRule
 * @param lead
 */
export function* saveCommissionRule(lead: StoreAction) {
  try {

    const toSave: CommissionRuleToSave = {
      commission: 0,
      endIssueDate: '',
      id: null,
      maxAmount: 0,
      minAmount: 0,
      startIssueDate: '',
      utmContent: '',
      leadGenerator: {
        id: null,
        name: ''
      },
    };

    const names = Object.keys(lead.payload);

    names.forEach(value => {
      if (lead.payload[value]) {
        toSave[value] = lead.payload[value];
      }
    });

    const fromApi: any = yield call(apiRequest, API.COMMISSION_RULE, "POST", toSave);

    if (fromApi) {
      yield all([
        call(getCommissionRule),
        put({
          type: ActionTypes.COMMISSION_RULE_SAVED
        })
      ]);
    }
  } catch (error) {
    console.error(error)
  }
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.GET_COMMISSION_RULE, getCommissionRule),
    takeLatest(ActionTypes.SAVE_COMMISSION_RULE, saveCommissionRule),
  ]);
}
